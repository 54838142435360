import React from 'react'

const Clients = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/TeamBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> clients </h6>
                            <p>
                                We are an award-winning interdisciplinary design practice that believes in delivering enriching design solutions through a value–driven process empowering all stakeholders and the environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-clients'>
                <div className="container-fluid laila-semibold">
                    <div className=''>
                        <h1>
                            How we Work
                        </h1>
                    </div>
                    <div className="row d-flex justify-content-around g-5">
                        <div className="col-lg-6 col-md-6">
                            <h2>
                                An Attitude of Gratitude
                            </h2>
                            <p>
                                No matter what the scale or type of work – it is a privilege for us to work on a project. Giving anything short of our best selves to our work is untrue to ourselves.
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <h2>
                                Bring Passion to all we do
                            </h2>
                            <p>
                                ‍Above all else, we believe in passion as a governing principle that supersedes all logic and bring it as an overriding attitude to all we do.
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <h2>
                                Honouring Relationships & Our Word
                            </h2>
                            <p>
                                ‍We deeply respect our clients and their businesses, our collaborators, our vendors, our contractors, our teammates, our society and above all our planet. To this end, we strive to bring out the best in each of these relationships.
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <h2>
                                Genius as an act of the Collective
                            </h2>
                            <p>
                                ‍Our process works best when each stakeholder contributes their slice of genius to a project – hence success is perceived as a collective accomplishment rather than an act of individual authorship.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-clients'>
                <div className="container-fluid laila-semibold">
                    <div className=''>
                        <h1>
                            Clients
                        </h1>
                    </div>
                    <div className="row d-flex justify-content-around g-5">
                        <div className="col-lg-6 col-md-6">
                            <h2>
                                A
                            </h2>
                            <p>
                                Abhishek Gupta
                                <br />
                                Aditya Birla Group
                                <br />
                                Aga Khan Foundation
                                <br />
                                Al Hamra Resort Oman
                                <br />
                                Aliel Jewellery DFC Dubai
                                <br />
                                Alliance Francaise de Delhi
                                <br />
                                Aloft Hotels
                                <br />
                                Ambuja Neotia Group
                                <br />
                                Anjum Singh
                                <br />
                                Ankit Chona
                                <br />
                                Antara Dehradun
                                <br />
                                Asahi Glass
                                <br />
                                Ashish Dhawan
                                <br />
                                Ashoka University
                                <br />
                                Asian Paints
                                <br />
                                Ather Energy
                                <br />
                                Atul & Arti Kirloskar
                                <br />
                            </p>

                            <h2>
                                B
                            </h2>
                            <p>
                                Bennet Coleman Group
                                <br />
                            </p>

                            <h2>
                                C
                            </h2>
                            <p>
                                Caara Hospitality
                                <br />
                                Café Lota, Crafts Museum
                                <br />
                                Carma
                                <br />
                                Central Park
                                <br />
                                City Palace Jaipur
                                <br />
                                Copper Chimney
                                <br />
                            </p>

                            <h2>
                                D
                            </h2>
                            <p>
                                Devanya
                                <br />
                                Devi Art Foundation
                                <br />
                                Dhun, Jaipur
                                <br />
                                Diva by Ritu Dalmia
                                <br />
                                Dixon Technologies
                                <br />
                                DLF
                                <br />
                            </p>

                            <h2>
                                E
                            </h2>
                            <p>
                                Eicher Motors
                                <br />
                                Ekaya Banaras
                                <br />
                                Everest Industries
                                <br />
                            </p>

                            <h2>
                                F
                            </h2>
                            <p>
                                Fashion Design Council of India
                                <br />
                                Fastrack
                                <br />
                                Firefox
                                <br />
                                FTV
                                <br />
                                Future Group
                                <br />
                            </p>

                            <h2>
                                G
                            </h2>
                            <p>
                                Gaurav Gupta
                                <br />
                                Godrej Properties Ltd
                                <br />
                                Good Earth
                                <br />
                                Government of India
                                <br />
                                Government of Odisha
                                <br />
                                Government of Punjab
                                <br />
                                Goyal & Co
                                <br />
                            </p>

                            <h2>
                                H
                            </h2>
                            <p>
                                Hard Rock Café
                                <br />
                                Hero MotoCorp Ltd
                                <br />
                                Hilton Hotels
                                <br />
                                Hindustan Unilever
                                <br />
                                Hyatt Corporation
                                <br />
                            </p>

                            <h2>
                                I
                            </h2>
                            <p>
                                Indian Institute of Art and Design
                                <br />
                                INTACH
                                <br />
                                Isprava Vesta
                                <br />
                            </p>

                            <h2>
                                J
                            </h2>
                            <p>
                                Jamie Oliver Restaurants
                                <br />
                                JK Tyres
                                <br />
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <h2>
                                K
                            </h2>
                            <p>
                                Kama Ayurveda
                                <br />
                                Kanika & Aditya Kapoor
                                <br />
                                Karan Paul
                                <br />
                                Khaaja Chowk
                                <br />
                                Khoj Studios, Khirkee
                                <br />
                                Kohler
                                <br />
                            </p>

                            <h2>
                                L
                            </h2>
                            <p>
                                Leisure Hotels
                                <br />
                                Lemon Tree Hotels
                                <br />
                                Levi’s Strauss & Co
                                <br />
                                Life Yoga
                                <br />
                                Lodha Developers
                                <br />
                            </p>

                            <h2>
                                M
                            </h2>
                            <p>
                                M2K Group
                                <br />
                                Madura Garments
                                <br />
                                Manav Gangwani
                                <br />
                                Manyata Developers
                                <br />
                                Masti Lounge
                                <br />
                                Max Estates
                                <br />
                                McNROE Consumer Products
                                <br />
                                Mehrangarh Museum Trust
                                <br />
                                MKM Group
                                <br />
                                Mrigesh Bhandari
                                <br />
                            </p>

                            <h2>
                                N
                            </h2>
                            <p>
                                Namrata Joshipura
                                <br />
                                Nandita Basu
                                <br />
                                National Crafts Museum, New Delhi
                                <br />
                                Novotel Hotels
                                <br />
                            </p>

                            <h2>
                                O
                            </h2>
                            <p>
                                Ogaan
                                <br />
                                Organic India
                                <br />

                            </p>

                            <h2>
                                P
                            </h2>
                            <p>
                                Parallels IP Holdings GmbH
                                <br />
                                Pidilite Group
                                <br />
                                Piramal Realty
                                <br />
                                Postcard Hotels
                                <br />
                                Priya Paul
                                <br />
                                Puri Constructions
                                <br />
                                PVR Cinemas
                                <br />
                            </p>

                            <h2>
                                R
                            </h2>
                            <p>
                                RAAS Hotels
                                <br />
                                Radisson Hotels
                                <br />
                                Rajesh Pratap Singh
                                <br />
                                Reliance Industries
                                <br />
                                Rishad Currimjee
                                <br />
                                Ritu Kumar
                                <br />
                                Rohit Bal
                                <br />
                                Rohit Gandhi-Rahul Khanna
                                <br />
                                Roseate
                                <br />
                                Royal Enfield
                                <br />
                            </p>

                            <h2>
                                S
                            </h2>
                            <p>
                                Sagar Ratna
                                <br />
                                Salaam Balak Trust
                                <br />
                                Shapoorji Pallonji & Co
                                <br />
                                Silverglades Holdings
                                <br />
                                Subodh Gupta & Bharti Kher
                                <br />
                                Sunita Shekhawat
                                <br />
                            </p>

                            <h2>
                                T
                            </h2>
                            <p>
                                Taj Hotels
                                <br />
                                Tanishq
                                <br />
                                Tara & Sahil Vachani
                                <br />
                                Tata Sons
                                <br />
                                Tata Steel
                                <br />
                                The Golkonda Hotel
                                <br />
                                The Indian Hotels Company Limited
                                <br />
                                The Lakshmi Mills Company Limited
                                <br />
                                The Oberoi Group
                                <br />
                                The Park Hotels
                                <br />
                                Tribe by Amrapali
                                <br />
                                Trilegal
                                <br />
                            </p>

                            <h2>
                                V
                            </h2>
                            <p>
                                Volvo Eicher
                                <br />
                            </p>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Clients