import React from 'react'
import "./Common.css"

const Disclaimer = () => {
    return (
        <>

            <section className='laila-semibold'>
                <div className="container-fluid">
                    <div className="container">
                        <div className='Disclaimer-Page'>
                            <h2>
                                Disclaimer
                            </h2>
                            <h4>
                                GENERAL
                            </h4>
                            <p>
                                The materials presented in the Studio Lotus web pages are provided voluntarily as a service to our clients.
                            </p>
                            <h4>
                                DISCLAIMER OF LIABILITY
                            </h4>
                            <p>
                                While every effort will be made to ensure that the information contained within the Website is accurate and up to date, Studio Lotus makes no warranty, representation or undertaking whether expressed or implied, nor does it assume any legal liability, whether direct or indirect, or responsibility for the accuracy, completeness, or usefulness of any information. We may edit, delete or modify any of the terms and conditions contained in this Agreement, at any time and in our sole discretion, by posting a notice or a new Agreement on our site. Your continued visit to our website following our posting of a change notice or new Agreement on our site will constitute binding acceptance of the change.
                            </p>
                            <h4>
                                COPYRIGHT
                            </h4>
                            <p>
                                Unless otherwise stated, copyright of all material published within this website is reserved by Studio Lotus. All rights are reserved. No part may be reproduced or re-used for any purposes whatsoever without the prior written permission of Studio Lotus. For further information please contact Studio Lotus via email on media@studiolotus.in
                            </p>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Disclaimer