import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsAll = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorsAllBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> interiors </h6>
                            <p>
                                We follow a deeply contextual approach to our work and combine this with a strong focus on building the tactile and sensory qualities and experiential nature of the space through an in-depth understanding of methodologies, materials and story-telling.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid ">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quadrant House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A multi-generational residence on
                                        the outskirts of Raipur, The
                                        Quadrant House blends new notions
                                        of luxury with nature and light.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className='Comman-Paragraph'>
                                            <p>
                                                <span> We use our core ability of narrative-building as a strategic tool to craft bespoke interior spaces that elevate the user experience. </span> We do intensive studies to gain a thorough understanding of the client’s needs and aspirations, and partner with them to create industry benchmarks.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection1-2.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> IFJ </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    The Jaypore store creates a modular
                                                    immersive retail experience reflecting
                                                    the brand’s core values and unique
                                                    Indian signature.
                                                </p>
                                                <NavLink to='' className='btn2_1 btn2'>
                                                    <h1> READ MORE</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection1-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Motodo Pizzeria </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        This new eatery by Reliance Brands
                                        Limited brings the quaint charm of
                                        Southern Italy to BKC's Jio
                                        World Drive
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> See Saw Cafe </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        An All-Day Pet-Friendly Cafe at
                                        Jio World Drive features a
                                        Nature-Inspired Palette to echo
                                        its fresh, homegrown menu.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='Comman-Paragraph'>
                                <p>
                                    The interdisciplinary nature of our team helps us blur the edges between distinct domain strengths such as furniture, branding, lighting and textile design.  <span> We collaborate with the best minds in the industry to deliver integrated solutions that extend the brief and maximise impact.  </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Krushi Bhawan</h1>
                                    <h5> Institutional | Workplace </h5>
                                    <p>
                                        Krushi Bhawan illustrates how a
                                        Government facility can extend
                                        itself to celebrate local context,
                                        craftsmanship and sustainability ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection3-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> RAAS Jodhpur </h1>
                                                <h5> Adaptive Re-Use | Hotels </h5>
                                                <p>
                                                    World’s Best Holiday Building at
                                                    WAF Barcelona and an Aga Khan Award
                                                    for Architecture Nominee, the luxury
                                                    boutique hotel of RAAS ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection3-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Villa in the Woods </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    An Eco-Conscious Sanctuary in the Himalayas
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection3-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Earth and Glass House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Interconnected Volumes establish a dialogue between the Indoors and the Outdoors at this weekend retreat
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className='Comman-Paragraph'>
                                <p>
                                    Our extensive experience and competency in the Interior Design domain establishes the foundation of a sound operations system that ensures efficiency from concept to execution.  <span> We bring our comprehensive grasp of materials and working with craftsmen to drive optimisation of spaces, timelines and economics of design. </span>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection4-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quorum </h1>
                                    <h5> Leisure | F & B </h5>
                                    <p>
                                        A seamless Spatial Experience combining
                                        Leisure & Learning, crafted to create
                                        an Urban Commune
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection5-1.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> The Plan </h1>
                                    <h5>  </h5>
                                    <p>
                                        The commercial space of the Raro
                                        House of Fashion brand in New Delhi
                                        reflects the turning point that
                                        women's fashion is experiencing ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection5-2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Architect and Interiors India </h1>
                                    <h5>  </h5>
                                    <p>
                                        Living Room Trends
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection5-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Brand Experiences </h5>
                                    <p>
                                        Recognised by WAN, Fast Company
                                        and Architizer A+ Awards, Imagine
                                        Studio at The Trees weaves nature,
                                        heritage and urbanism through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection6-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Integrated Production Facility for Organic India </h1>
                                    <h5> Workplace </h5>
                                    <p>
                                        A LEED Platinum-Rated development
                                        that encompasses the full production
                                        process of the Wellness Brand.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection6-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Masti Cocktails & Cuisine </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A vibrant and eclectic F&B
                                                    destination, Masti is infused
                                                    with all the personality of
                                                    India, without any of the
                                                    clichés.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection6-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Private Residence in Lutyens' Delhi </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Breathing New Life Into an Existing Neoclaasical
                                                    Structure for 21st-Century Living
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection6-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Baradari At City Palace Jaipur </h1>
                                    <h5> Adaptive Re-Use | F & B </h5>
                                    <p>
                                        Creative Re-use Winner at INSIDE - World
                                        Festival of Interiors in Berlin, Baradari
                                        at City Palace Jaipur explores how design
                                        can bring ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection7-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Stepped House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A hill retreat that maximises
                                        the stunning panoramas of Coonoor,
                                        the form of the Stepped House
                                        is arrived at from the
                                        attempt to make ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection7-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> RAAS Devigarh </h1>
                                                <h5> Adaptive Re-Use | Hotels </h5>
                                                <p>
                                                    Located in the Aravali Hills, RAAS Devigarh
                                                    is an all-suites luxury hotel housed within
                                                    an 18th century palace fortress.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection7-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Twin Cubes and a Court   </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Stark cubic volumes comprise this pair of
                                                    minimalist residences located alongside a
                                                    busy highway, connected by a tranquil
                                                    inner court. ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection7-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Ather Experience Centre </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        An immersive experience of the Electric Automobile brand
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection8-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                    <h5> Retail | Adaptive Re-Use | F & B </h5>
                                    <p>
                                        A sensitively-approached award-winning design,
                                        Café Lota & the Museum Shop revitalizes
                                        the National Crafts Museum hub using local ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection8-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Upstage Club, Roseate House </h1>
                                                <h5> Leisure | Hotels | F & B </h5>
                                                <p>
                                                    A Contemporary Club that Embodies the
                                                    Flagship Brand’s Business and Hospitality
                                                    Experience
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection8-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> The House of Celeste  </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A gourmet restaurant in Gurugram designed
                                                    to echo the country's enduring legacy of
                                                    regional cuisines through a series of
                                                    visual storytelling ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection8-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Rajesh Pratap Singh Flagship Store </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        The humble tailor’s tool, a pair of scissors,
                                        is elevated in its unique use as a metaphor
                                        to craft the Rajesh Pratap Singh Flagship ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection9-1.png")} alt="" />
                                <div className='content'>
                                    <h1> House of Raisons Noida </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A traditional shopping experience
                                        is represented as a contemporary
                                        retail narrative communicating
                                        the luxury and heritage of the brand ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection9-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal Enfield Flagship Store </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    Crafting a brand reinvention for Royal
                                                    Enfield based on a new positioning
                                                    ‘Pure Motorcycling’, the multiple
                                                    award-winning store ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection9-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> The House of Celeste  </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A gourmet restaurant in Gurugram
                                                    designed to echo the country's
                                                    enduring legacy of regional
                                                    cuisines through a series of visual
                                                    storytelling ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection9-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Royal Enfield Khan Market  </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A store that walks you through the
                                        core values of the brand through an
                                        immersive story, the Royal Enfield
                                        flagship in Khan Market creates ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection10-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Glasxperts Experience Centre  </h1>
                                    <h5> Brand Experiences </h5>
                                    <p>
                                        An optical layering of immersive display,
                                        applications and story-telling reveals a
                                        magical experience for the Glasxperts
                                        flagship, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection10-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Jamie’s Pizzeria New Delhi  </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    The New Delhi outlet of Jamie’s
                                                    Pizzeria creates a degree of variance
                                                    within the framework of its main design
                                                    principles to add contextual ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorsAllSection10-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> The Shop  </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    The challenges of a tight space and
                                                    budget were overcome to create an
                                                    elevated retail experience for
                                                    ‘The Shop’, which with its ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorsAllSection10-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Rohit Bal Privé </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        IIID Award Winner for the best designed
                                        retail space, the store uses layering and
                                        a play of raw and elaborately-detailed
                                        elements to ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsAll