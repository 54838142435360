import React, { useRef, useState } from 'react'
import "./Contact.css"
import { NavLink } from 'react-router-dom'

const Contact = () => {


    const form = useRef();

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [designation, setDesignation] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.phone = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + "FirstName:" + firstname + "LastName:" + lastname + "Company:" + companyname + "Designation:" + designation + "Phone:" + phone + "EMAIL:" + email + "MASSAGE:" + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        alert("Thank you! Your submission has been received!")
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setDesignation("");
        setPhone("");
        setEmail("");
        setMassage("");
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!firstname) {
            errors.firstname = "First Name Is Requried";
        }


        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }


        if (!companyname) {
            errors.companyname = "Company Name Is Requried";
        }


        if (!designation) {
            errors.designation = "Designation Name Is Requried";
        }


        if (!phone) {
            errors.phone = "Number Is Requried";
        }
        else if (phone.length !== 10) {
            errors.phone = "Number Must Have 10 Digit";
        }

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid p-0 ">
                    <div className="imageBG Form-Main" >
                        <img className='' src={require("../Img/TeamBG.png")} alt="" />

                        <div className="container p-lg-2 Form-SubMain" >
                            <div className="row text-white d-flex justify-content-center " >
                                <div className="col-lg-12">
                                    <div className="row d-flex justify-content-center  p-lg-3 laila-semibold" >
                                        <div className="col-lg-5 col-md-6 " style={{ backgroundColor: "#fff" }}>
                                            <div className='p-3'>
                                                <h2 className='mb-4 mt-3 text-danger'>Contact Us</h2>
                                                <form ref={form} onSubmit={loginHandle} className="row g-3" style={{ borderRadius: "0px 0px 0px 0px", }}>
                                                    <div class="col-lg-6 col-md-6 col-6">
                                                        <input type="text" className="form-control text-dark UbuntuFONTFAMILY" placeholder='first name' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputName" name="firstname"
                                                            onChange={e => setFirstName(e.target.value)} value={firstname}
                                                        />
                                                        {errors.firstname && <div className='text-danger'>{errors.firstname}</div>}
                                                    </div>

                                                    <div class="col-lg-6 col-md-6 col-6">
                                                        <input type="text" className="form-control text-dark UbuntuFONTFAMILY" placeholder='last name' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputName" name="lastname"
                                                            onChange={e => setLastName(e.target.value)} value={lastname}
                                                        />
                                                        {errors.lastname && <div className='text-danger'>{errors.lastname}</div>}
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-6'>
                                                        <input type="email" className="form-control boderRadius UbuntuFONTFAMILY" placeholder='email ID' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputEmail4" name="email"
                                                            onChange={e => setEmail(e.target.value)} value={email}
                                                        />
                                                        {errors.email && <div className='text-danger'>{errors.email}</div>}
                                                    </div>

                                                    <div class="col-lg-6 col-md-6 col-6">
                                                        <input type="text" className="form-control text-dark UbuntuFONTFAMILY" placeholder='company name' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputName" name="name"
                                                            onChange={e => setCompanyName(e.target.value)} value={companyname}
                                                        />
                                                        {errors.companyname && <div className='text-danger'>{errors.companyname}</div>}
                                                    </div>

                                                    <div class="col-lg-6 col-md-6 col-6">
                                                        <input type="text" className="form-control text-dark UbuntuFONTFAMILY" placeholder='designation' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputName" name="name"
                                                            onChange={e => setDesignation(e.target.value)} value={designation}
                                                        />
                                                        {errors.designation && <div className='text-danger'>{errors.designation}</div>}
                                                    </div>

                                                    <div class="col-lg-6 col-md-6 col-6">
                                                        <input type="number" className="form-control UbuntuFONTFAMILY" placeholder='contact no' minLength='10' maxLength='10' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white" }} id="inputPhone" name="phone"
                                                            onChange={e => setPhone(e.target.value)} value={phone}
                                                        />
                                                        {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <textarea type="text" className="form-control UbuntuFONTFAMILY" placeholder='type your message here' style={{ borderRadius: "0px 0px 0px 0px", color: "#111", borderBottom: "1px solid #111", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", }} id="inputText3" name="message"
                                                            onChange={e => setMassage(e.target.value)} value={massage}
                                                        />
                                                        {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                                    </div>

                                                    <div className="col-lg-6 mt-lg-4 mb-3">
                                                        <button type="submit" className=" p-1 SendButton "  ><b className='' style={{ fontWeight: "600", fontSize: "15px" }}>SUBMIT</b></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6" style={{ backgroundColor: "#e74011" }}>
                                            <div className='p-3'>
                                                <h2 className='mb-4'>address</h2>
                                                <div className='border-bottom pb-3' style={{ fontSize: "13px",fontWeight:"400" }}>
                                                    F 301 First Floor <br />
                                                    Chaudhari Prem Singh House, Lado Sarai <br />
                                                    New Delhi 110030
                                                </div>
                                                <div className="row g-2 pt-3">
                                                    <div className='col-lg-6' style={{fontWeight:"200"}}>
                                                        <h6> Phone </h6>
                                                        <div className='' >
                                                            <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "13px" }} exact to="tel:91 11 4057 0808" ><b>+91 11 4057 0808</b></NavLink>
                                                        </div>

                                                        <div >
                                                            <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "13px" }} exact to="tel:91 11 4052 7979" ><b>+91 11 4052 7979</b></NavLink>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 Font_Alegreya' >
                                                        <h6> Email </h6>
                                                        <div className=''style={{fontWeight:"300"}}>
                                                            <NavLink className='text-white text-decoration-none' to="mailto:business@studiolotus.in"> <span className='text-center  ' >business@studiolotus.in</span></NavLink>
                                                        </div>

                                                        <div className=''style={{fontWeight:"300"}}>
                                                            <NavLink className='text-white text-decoration-none' to="mailto:media@studiolotus.in"> <span className='text-center  '>media@studiolotus.in</span></NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul className=" Icon d-flex  align-items-lg-center  roboto-regular border-top mt-3 pt-3" style={{ listStyle: "none" }} >
                                                    <li className="  ">
                                                        <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            f
                                                        </NavLink>
                                                    </li>

                                                    <li className=" ">
                                                        <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            t
                                                        </NavLink>
                                                    </li>

                                                    <li className="  ">
                                                        <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            in
                                                        </NavLink>
                                                    </li>

                                                    <li className=" ">
                                                        <NavLink className="text-decoration-none" style={{ color: "#fff", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            @
                                                        </NavLink>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>

            <section className='Map-Size'>
                <div className='m-0'>
                    <iframe className='w-100 m-0' title="gmap" style={{ height: "470px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.505302660919!2d77.19211377495276!3d28.52452788897815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1fe1d1a250b%3A0xd263c0b6033c86b3!2sStudio%20Lotus!5e0!3m2!1sen!2sin!4v1713425395785!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </section>

        </>
    )
}

export default Contact