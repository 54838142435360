import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Navbar.css"

const Navbar = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-none d-lg-block d-block">
                    <nav className="navbar navbar-expand-lg navbar p-0 " style={{ backgroundColor: "#fff" }}  >
                        <div className='navbar-brand'>
                            <NavLink to="/">
                                <img className='' style={{ height: "3.67rem", width: "200px" }} src={require("../Img/Logo2.png")} alt="icloudsoft_logo" />
                            </NavLink>
                        </div>
                        <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars "    ></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav d-flex justify-content-lg-between "  >
                                <li className="nav-item dropdown  ">
                                    <NavLink className="nav-link"  role="button" data-bs-toggle="dropdown" aria-expanded="false"  >
                                        Architecture
                                    </NavLink>
                                    <ul className="dropdown-menu"  >
                                        <li><NavLink className="dropdown-item" to="/all"> All </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/hospitality"> Hospitality </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/adaptive"> Adaptive Re-Use </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/institutional"> Institutional </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/culture"> Culture </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/mixeduse"> Mixed Use </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/homes"> Homes </NavLink></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link pe-lg-5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Interiors
                                    </NavLink>
                                    <ul className="dropdown-menu"  >
                                        <li><NavLink className="dropdown-item" to="/interiorsall"> All </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/retail"> Retail </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorsadaptivereuse"> Adaptive Re-Use </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorsinstitutional"> Institutional </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorsworkplace"> Workplace </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorsleisure"> Leisure </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorshomes"> Homes </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorshotels"> Hotels </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/interiorsfb"> F & B </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/brandexperiences"> Brand <br /> Experiences </NavLink></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown ">
                                    <NavLink className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        News and Views
                                    </NavLink>
                                    <ul className="dropdown-menu"  >
                                        <li><NavLink className="dropdown-item" to="/newsandviews"> All </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/featuredin"> Featured In </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/awards"> Awards </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/blogs"> Blogs </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/events"> Events </NavLink></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown " >
                                    <NavLink className="nav-link pe-lg-5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        about us
                                    </NavLink>
                                    <ul className="dropdown-menu"  >
                                        <li><NavLink className="dropdown-item" to="/aboutus"> All </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ourvalues"> values </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/theteam"> team </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/clients"> clients </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/careers"> Careers </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/contact"> Contact </NavLink></li>
                                    </ul>
                                </li>

                            </ul>

                            <ul className="navbar-nav Icon d-flex justify-content-lg-end align-items-lg-center  roboto-regular" >
                                <li className="  ">
                                    <NavLink className="text-decoration-none" style={{ color: "#282828", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        f
                                    </NavLink>
                                </li>

                                <li className=" ">
                                    <NavLink className="text-decoration-none" style={{ color: "#282828", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        t
                                    </NavLink>
                                </li>

                                <li className="  ">
                                    <NavLink className="text-decoration-none" style={{ color: "#282828", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        in
                                    </NavLink>
                                </li>

                                <li className=" ">
                                    <NavLink className="text-decoration-none" style={{ color: "#282828", fontSize: "15px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        @
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none  d-md-block d-sm-block">
                    <nav className="navbar navbar-expand-lg p-0 " style={{ backgroundColor: "#fff" }} >
                        <div className="container-fluid p-0 ">
                            <div className=''>
                                <NavLink to="/">
                                    <img className='' style={{ height: "3.67rem", width: "200px" }} src={require("../Img/Logo2.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler bg-light me-4" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0" style={{ backgroundColor: "#F04E23" }} id="navbarNavDropdown">
                                <ul className="navbar-nav roboto-regular d-flex justify-content-lg-around align-items-lg-center w-100 p-3"  >
                                    <li className="nav-item #submenu nav-link " data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-2 " style={{ fontSize: "12px", padding: "10px 20px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> ARCHITECTURE </div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#F04E23", border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/all" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/hospitality" >
                                                            <div className='dropdown-item '> HOSPITALITY </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/adaptive" >
                                                            <div className='dropdown-item'> ADAPTIVE RE-USE </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/institutional" >
                                                            <div className='dropdown-item'> INSTITUTIONAL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/culture" >
                                                            <div className='dropdown-item '> CULTURE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/mixeduse" >
                                                            <div className='dropdown-item '> MIXED USE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/homes" >
                                                            <div className='dropdown-item '> HOMES  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-2 " style={{ fontSize: "12px", padding: "10px 20px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Interiors </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#F04E23", border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/interiorsall" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/retail" >
                                                            <div className='dropdown-item '> RETAIL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsadaptivereuse" >
                                                            <div className='dropdown-item'> ADAPTIVE RE-USE </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsinstitutional" >
                                                            <div className='dropdown-item'> INSTITUTIONAL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsworkplace" >
                                                            <div className='dropdown-item '> WORKPLACE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsleisure" >
                                                            <div className='dropdown-item '> LEISURE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorshomes" >
                                                            <div className='dropdown-item '> HOMES  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorshotels" >
                                                            <div className='dropdown-item '> Hotels  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsfb" >
                                                            <div className='dropdown-item '> F & B  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/brandexperiences" >
                                                            <div className='dropdown-item '> Brand Experiences  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-2 " style={{ fontSize: "12px", padding: "10px 20px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> News and Views </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#F04E23", border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/newsandviews" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/featuredin" >
                                                            <div className='dropdown-item '> Featured In </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/awards" >
                                                            <div className='dropdown-item'> Awards </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/blogs" >
                                                            <div className='dropdown-item'> Blogs </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/events" >
                                                            <div className='dropdown-item '> Events  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-2 " style={{ fontSize: "12px", padding: "10px 20px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> about us </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#F04E23", border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/aboutus" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/ourvalues" >
                                                            <div className='dropdown-item'> values </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/theteam" >
                                                            <div className='dropdown-item '> team </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/clients" >
                                                            <div className='dropdown-item'> Clients </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/careers" >
                                                            <div className='dropdown-item '> Careers  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/contact" >
                                                            <div className='dropdown-item '> Contact  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

        </>
    )
}

export default Navbar