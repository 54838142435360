import React from 'react'
import { NavLink } from 'react-router-dom'

const Retail = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/RetailBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> retail </h6>
                            <p>
                                We work intensively to get under the skin of a brand and craft immersive narratives that elevate its engagement with the audience. We use design to empower the connect of a brand with its customer and create a memorable experience that builds better business, recall and impact.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RARO House of Fashion </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A cohesive retail experience tying
                                        together two unique clothing brands.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection1-2.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> IFJ </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    The Jaypore store creates a modular
                                                    immersive retail experience reflecting
                                                    the brand’s core values and unique
                                                    Indian signature.
                                                </p>
                                                <NavLink to='' className='btn2_1 btn2'>
                                                    <h1> READ MORE</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Retail | Adaptive Re-Use | F & B | Brand Experiences </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the next
                                                    level of immersive brand experiences
                                                    for Royal Enfield, Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Paro by Good Earth </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        Crafted as an urban sanctuary,
                                        the concept store for Paro
                                        invites visitors to a unique
                                        retail experience.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-3">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Ather Experience Centre </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        An immersive experience of the Electric Automobile brand
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Cousu d’Or  </h1>
                                                <h5> Retail | Brand Experiences </h5>
                                                <p>
                                                    A contemporary and surrealist
                                                    exhibition for a stellar collection
                                                    of couture bridal-wear.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Jaypore </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Gaurav Gupta Flagship Store </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        Architecture and fashion combine
                                        to create a surrealist dreamscape
                                        for renowned Indian fashion
                                        designer
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                    <h5> Retail | Adaptive Re-Use | F & B </h5>
                                    <p>
                                        A sensitively-approached award-winning
                                        design, Café Lota & the Museum Shop
                                        revitalizes the National Crafts
                                        Museum hub using local ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection3-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> House of Raisons Noida </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    A traditional shopping experience
                                                    is represented as a contemporary
                                                    retail narrative communicating
                                                    the luxury and heritage of
                                                    the brand ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection3-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Abhishek Gupta Flagship Store  </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    Embodying fashion designer
                                                    Abhishek Gupta’s approach in
                                                    creating contemporary signature
                                                    styles deeply rooted in craft,
                                                    the concept ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection3-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Rajesh Pratap Singh Flagship Store </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        The humble tailor’s tool, a pair
                                        of scissors, is elevated in its
                                        unique use as a metaphor to craft
                                        the Rajesh Pratap Singh Flagship ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection4-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Rivet – Levi’s Concept Boutique  </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        Inspired by industrial craft
                                        used to make Rivet’s premium
                                        international denim range,
                                        the award-winning store
                                        creates an ambience ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection4-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal Enfield Flagship Store </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    Crafting a brand reinvention for
                                                    Royal Enfield based on a new
                                                    positioning ‘Pure Motorcycling’,
                                                    the multiple award-winning store ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection4-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Ekaya Banaras </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    Contemporising the traditional
                                                    retail sari buying experience for
                                                    one of India’s finest handcrafted
                                                    textile brands, the concept draws ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection4-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Royal Enfield Khan Market </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A store that walks you through
                                        the core values of the brand
                                        through an immersive story,
                                        the Royal Enfield flagship in
                                        Khan Market creates ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection5-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Good Earth Khan Market </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        The challenge of fitting the
                                        most diverse luxury lifestyle
                                        product range in the country
                                        in the smallest possible space
                                        at the most ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection5-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Manav Gangwani Couture </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    A contemporary abstraction of a timeless
                                                    form creates the ideal shell to showcase
                                                    the dramatic yet classic silhouettes of
                                                    fashion designer ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection5-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Good Earth Saket </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    A visual mélange of modern India
                                                    with all its nuances and contradictions
                                                    reveals the meandering experience for
                                                    the flagship store ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection5-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Gaurav Gupta Flagship Store  </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        Finalist at INSIDE Awards Singapore,
                                        the Gaurav Gupta Flagship Store
                                        handcrafts luxury through everyday
                                        materials to shape a raw canvas ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection6-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Rohit Bal Privé </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        IIID Award Winner for the best
                                        designed retail space, the
                                        store uses layering and a play
                                        of raw and elaborately-detailed
                                        elements to ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection6-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Mehrangarh Museum Shop </h1>
                                                <h5> Retail | Adaptive Re-Use </h5>
                                                <p>
                                                    Detailed research and an
                                                    insightful approach to adaptive
                                                    reuse removes layers of modern
                                                    day renovations to reveal the
                                                    exquisite original ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection6-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Nandita Basu Flagship  </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    A diminutive but versatile boutique
                                                    for Nandita Basu’s high-end Indian
                                                    fashion label imbues her focus on
                                                    the construct of form, finish ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection6-4.png")} alt="" />
                                <div className='content'>
                                    <h1> The Shop  </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        The challenges of a tight space
                                        and budget were overcome to create
                                        an elevated retail experience for
                                        ‘The Shop’, which with its ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection7-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Fastrack Flagship Store </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A radical approach in designing a new
                                        retail identity for Fastrack helps the
                                        leading youth fashion accessories
                                        brand physically establish ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/RetailSection7-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Viavero </h1>
                                                <h5> Retail </h5>
                                                <p>
                                                    The idea of an affordable luxury
                                                    brand is translated by emphasising
                                                    the collections through sleek
                                                    display devices in a subtle interior ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/RetailSection7-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Carma  </h1>
                                    <h5> Retail </h5>
                                    <p>
                                        A heightened sense of space
                                        created through illusion fashions
                                        the premium multi-designer women’s
                                        couture store into a mirage
                                        of grandness. ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Retail