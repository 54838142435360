import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsAdaptiveReUse = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorAdUseBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> adaptive re-use </h6>
                            <p>
                                We believe in crafting memories of the site into paradigms that heighten the relationship between the old and the new. We explore contemporary expressions of traditional skill and material alongside the historic to create new meaning and new conversations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorAdUseSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur </h1>
                                    <h5> Adaptive Re-Use | Hotels </h5>
                                    <p>
                                        World’s Best Holiday Building at
                                        WAF Barcelona and an Aga Khan Award
                                        for Architecture Nominee, the luxury
                                        boutique hotel of RAAS ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorAdUseSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Baradari At City Palace Jaipur </h1>
                                                <h5> Adaptive Re-Use | F & B </h5>
                                                <p>
                                                    Creative Re-use Winner at INSIDE -
                                                    World Festival of Interiors in Berlin,
                                                    Baradari at City Palace Jaipur
                                                    explores how design can bring ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorAdUseSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Retail | Adaptive Re-Use | F & B | Brand Experiences </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the next
                                                    level of immersive brand experiences
                                                    for Royal Enfield, Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorAdUseSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Brand Experiences </h5>
                                    <p>
                                        Recognised by WAN, Fast Company
                                        and Architizer A+ Awards, Imagine
                                        Studio at The Trees weaves nature,
                                        heritage and urbanism through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-3">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorAdUseSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur </h1>
                                    <h5> Adaptive Re-Use | Hotels </h5>
                                    <p>
                                        Located in the Aravali Hills, RAAS Devigarh is an all-suites luxury hotel housed within an 18th century palace fortress.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorAdUseSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                                <h5> Retail | Adaptive Re-Use | F & B </h5>
                                                <p>
                                                    A sensitively-approached award-winning
                                                    design, Café Lota & the Museum Shop
                                                    revitalizes the National Crafts Museum
                                                    hub using local ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorAdUseSection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Coast Café </h1>
                                                <h5> Adaptive Re-Use | F & B </h5>
                                                <p>
                                                    In the repurposing of spaces for Ogaan,
                                                    one of India’s pioneering multi-brand
                                                    fashion stores, the building and
                                                    interior of Coast ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorAdUseSection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Khoj Studios </h1>
                                    <h5> Adaptive Re-Use | Institutional </h5>
                                    <p>
                                        An experimental art laboratory in
                                        an Urban village ghetto in New Delhi,
                                        Khoj is designed as an organic
                                        space where dialogue, exchange, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-3">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorAdUseSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Mehrangarh Museum Shop </h1>
                                    <h5> Retail | Adaptive Re-Use </h5>
                                    <p>
                                        Detailed research and an insightful
                                        approach to adaptive reuse removes layers
                                        of modern day renovations to reveal
                                        the exquisite original ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsAdaptiveReUse