import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsHomes = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorsHomesBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> homes </h6>
                            <p>
                                Our homes celebrate context and individuality to address the residents’ needs and aspirations across scales and locations. We explore ways to elevate the quality of life through our understanding of spaces, structure, materials, techniques and craftsmanship and imbue it with bespoke detailing to create intimate experiences people want to come home to.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomesSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quadrant House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A multi-generational residence
                                        on the outskirts of Raipur,
                                        The Quadrant House blends
                                        new notions of luxury with
                                        nature and light.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomesSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Villa in the Woods </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    An Eco-Conscious Sanctuary in the Himalayas
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomesSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Private Residence in Lutyens' Delhi </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Breathing New Life Into an
                                                    Existing Neoclaasical Structure
                                                    for 21st-Century Living
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomesSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Earth and Glass House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Interconnected Volumes establish
                                        a dialogue between the Indoors
                                        and the Outdoors at this weekend retreat
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHomesSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Stepped House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A hill retreat that maximises the
                                        stunning panoramas of Coonoor,
                                        the form of the Stepped House
                                        is arrived at from the attempt to make ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorHomesSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Stacked House  </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    A Compact Residence in a Dense
                                                    Urban Locality Looks Inwards to
                                                    Elevate the Living Experience.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorHomesSection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Residence at Aurangzeb Lane </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    A minimally invasive approach converts
                                                    an existing office into a tasteful
                                                    apartment with a shell that had
                                                    character and bare aesthetic ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHomesSection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Twin Cubes and a Court </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Stark cubic volumes comprise this
                                        pair of minimalist residences located
                                        alongside a busy highway, connected
                                        by a tranquil inner court. ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHomesSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Kukreja House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A city bungalow that creates independent
                                        living spaces with a strong sense of
                                        interconnectedness and brings in warmth,
                                        intimacy, light ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHomesSection3-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Nair House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Responding to the context of Coonoor,
                                        a dramatic roof form articulates a
                                        conceptual residence into piece of
                                        art nestled the beautiful ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsHomes