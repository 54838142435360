import React from 'react'
import { NavLink } from 'react-router-dom'

const Institutional = () => {
    return (
        <>
            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InstitutionalBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> architecture </h5>
                            <h6> institutional </h6>
                            <p>
                                We work closely with our clients to create purposeful institutional projects that extend their brief and vision. Whether a government facility, an art studio, a museum or school, our designs empower the equations between context and building, spaces and community, and process and sustainability.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InstitutionalSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Krushi Bhawan </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        Krushi Bhawan illustrates how a
                                        Government facility can extend
                                        itself to celebrate local
                                        context, craftsmanship
                                        and sustainability ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InstitutionalSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Khoj Studios </h1>
                                                <h5> Adaptive Re-Use | Institutional </h5>
                                                <p>
                                                    An experimental art laboratory
                                                    in an Urban village ghetto in
                                                    New Delhi, Khoj is designed as
                                                    an organic space where
                                                    dialogue, exchange, ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InstitutionalSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> National Museum of Afghanistan  </h1>
                                                <h5> Institutional </h5>
                                                <p>
                                                    Bridging the old and the new,
                                                    the entry for the International
                                                    Architectural Ideas Competition
                                                    for the National Museum of
                                                    Afghanistan ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InstitutionalSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Mehrangarh Fort Visitor Centre </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        Focused on sustainability and
                                        flexibility, Studio Lotus’s
                                        design for new cultural annexes
                                        at the historic precinct
                                        seeks to create ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid ">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InstitutionalSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> National Police Memorial  </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        The winning entry at the
                                        National Architectural Design
                                        Competition for the National
                                        Police Memorial gained praise
                                        for the thoughtful ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InstitutionalSection2-2.png")} alt="" />
                                <div className='content'>
                                    <h1> School of Integral Design </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        The campus architecture is conceived
                                        as a ﬂexible skeleton; a dynamic
                                        adaptive system that allows itself
                                        to be populated and shaped ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Institutional