import React from 'react'
import { NavLink } from 'react-router-dom'

const Hospitality = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/HospitalityBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> architecture </h5>
                            <h6> hospitality </h6>
                            <p>
                                Addressing the emerging needs of the most incisive user, we consistently apply our understanding of the context, local materials & technologies, authentic luxury and the brand narrative to innovate and deliver new benchmarks for experiences in hospitality.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HospitalitySection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur  </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        World’s Best Holiday Building at
                                        WAF Barcelona and an Aga Khan
                                        Award for Architecture Nominee,
                                        the luxury boutique hotel of RAAS ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HospitalitySection1-2.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> Elle Decor </h1>
                                                <h5> Hospitality </h5>
                                                <p>
                                                    The new Masti restaurant at
                                                    The Dubai Edition Hotel reminisces
                                                    Indian culture with maximalism
                                                    at its core.
                                                </p>
                                                <NavLink to='' className='btn2_1 btn2'>
                                                    <h1> READ MORE</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HospitalitySection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> RAAS Chhatrasagar </h1>
                                                <h5> Hospitality </h5>
                                                <p>
                                                    A wildlife camping experience that
                                                    reinterprets the historical,
                                                    and weaves in the biodiversity
                                                    of its setting with resilient
                                                    ways of ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HospitalitySection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Baradari At City Palace Jaipur </h1>
                                    <h5> Institutional | Adaptive Re-Use </h5>
                                    <p>
                                        Creative Re-use Winner at
                                        INSIDE - World Festival of
                                        Interiors in Berlin, Baradari
                                        at City Palace Jaipur explores
                                        how design can bring ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-3">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HospitalitySection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Royal enfield garage cafe  </h1>
                                    <h5> Hospitality | Adaptive Re-Use | Mixed Use </h5>
                                    <p>
                                        Crafting a Motorcycling Destination
                                        with a Goan Soul to launch the
                                        next level of immersive brand
                                        experiences for Royal Enfield,
                                        Studio ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HospitalitySection2-2.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> RAAS Devigarh </h1>
                                                <h5> Hospitality | Adaptive Re-Use </h5>
                                                <p>
                                                    Located in the Aravali Hills,
                                                    RAAS Devigarh is an all-suites
                                                    luxury hotel housed within
                                                    an 18th century palace fortress.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HospitalitySection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> The Club at The Trees </h1>
                                                <h5> Hospitality </h5>
                                                <p>
                                                    Anchoring the narrative of an
                                                    invigorated realm, The Club
                                                    at The Trees bridges the modern
                                                    and the historic, the built
                                                    and the natural, ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HospitalitySection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Kangra </h1>
                                    <h5> Hospitality </h5>
                                    <p>
                                        An ongoing boutique hotel in the
                                        Himalayas, the rhythmic form of
                                        Raas Kangra gives a new expression
                                        to the vernacular vocabulary of ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Hospitality