import React from 'react'
import { NavLink } from 'react-router-dom'

const BrandExperiences = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/BrandExpeBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> brand experiences </h6>
                            <p>
                                The user-centricity that is critical to all our work expresses itself most fluently in this realm. Innately understanding a brand’s proposition and being able to reinforce that through design - not only spatially, but also sensorially and emotively - is one of our core strengths.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/BrandExpeSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Brand Experiences </h5>
                                    <p>
                                        Recognised by WAN, Fast Company and
                                        Architizer A+ Awards, Imagine Studio
                                        at The Trees weaves nature, heritage
                                        and urbanism through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/BrandExpeSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Retail | Adaptive Re-Use | F & B | Brand Experiences </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the next
                                                    level of immersive brand experiences
                                                    for Royal Enfield, Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/BrandExpeSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Ather Experience Centre </h1>
                                                <h5> Retail | Brand Experiences </h5>
                                                <p>
                                                    An immersive experience of the Electric Automobile brand
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/BrandExpeSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Paro by Good Earth </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        Crafted as an urban sanctuary, the
                                        concept store for Paro invites visitors
                                        to a unique retail experience.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/BrandExpeSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Gaurav Gupta Flagship Store </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        Architecture and fashion combine to
                                        create a surrealist dreamscape for
                                        renowned Indian fashion designer
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/BrandExpeSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Kohler Experience Centre </h1>
                                                <h5> Brand Experiences </h5>
                                                <p>
                                                    As the first global flagship in India,
                                                    Kohler Experience Centre is designed
                                                    to celebrate innovation and diversity
                                                    of style in a dynamic, ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/BrandExpeSection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Volvo Eicher Corporate Showcase </h1>
                                                <h5> Brand Experiences </h5>
                                                <p>
                                                    Commemorating the joint venture between
                                                    the Volvo Group and Eicher Motors Ltd.,
                                                    the VECV Corporate Showcase creates
                                                    a storytelling ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/BrandExpeSection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Cousu d’Or  </h1>
                                    <h5> Retail | Brand Experiences </h5>
                                    <p>
                                        A contemporary and surrealist exhibition
                                        for a stellar collection of couture bridal-wear.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/BrandExpeSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Glasxperts Experience Centre  </h1>
                                    <h5> Brand Experiences </h5>
                                    <p>
                                        An optical layering of immersive display,
                                        applications and story-telling reveals
                                        a magical experience for the Glasxperts flagship, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BrandExperiences