import React from 'react'
import { NavLink } from 'react-router-dom'

const Careers = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/TeamBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> careers </h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-Careers laila-semibold'>
                <div className="container-fluid">
                    <div>
                        <h3>
                            At Studio Lotus, we take a deeply contextual approach to our work, and combine this with a strong focus on the tactile and sensory qualities of the space. Our design process looks at sustainability through the multiple lenses of cultural, social and environmental impact. We follow an iterative and incremental methodology of innovation and root our learning in history and local context. There is an active engagement in integrating localised skills and resources with state-of-the art materials and technologies. Our design process includes everyone from the principals to interns, fostering a culture centred on a high degree of individual ownership.
                        </h3>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-ImgSection'>
                <div className="container-fluid">
                    <div className="row g-4 mt-3">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Careers1.png")} alt="" />
                                <div className='content'>
                                    <h1> LIFE AT LOTUS </h1>
                                    <h5> Careers </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Careers2.png")} alt="" />
                                <div className='content'>
                                    <h1> VACANCIES </h1>
                                    <h5> Careers </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Careers3.png")} alt="" />
                                <div className='content'>
                                    <h1> TRAINEE PROGRAM </h1>
                                    <h5> Careers </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Careers