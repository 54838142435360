import React from 'react'
import { NavLink } from 'react-router-dom'

const FeaturedIn = () => {
    return (
        <>


            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/FeaturedInBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> featured in </h6>
                            <p>
                                Studio Lotus is a multi-disciplinary design practice whose work seamlessly weaves interior and exterior spaces.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews1.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IFJ </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The Jaypore store creates a modular
                                        immersive retail experience reflecting
                                        the brand’s core values and unique
                                        Indian signature.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Elle Decor </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The new Masti restaurant at The
                                        Dubai Edition Hotel reminisces
                                        Indian culture with maximalism
                                        at its core.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews4.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Volume Zero </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The House of Celeste explores
                                        India’s multiculturalism, celebrating
                                        its sense of togetherness and
                                        belonging.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews8.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Luxe Code </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Studio Lotus created this multi
                                        generational residence on the
                                        outskirts of Raipur in India,
                                        which blends new notions of
                                        luxury with ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews10.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> LA Journal </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The proposal of Imagine Studio at
                                        the Trees extends the boundaries
                                        of the gated development to include
                                        the community and the city, ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews13.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Elle Decor </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Motodo Pizzeria featured in Elle Decor
                                        on creating a festive atmosphere
                                        with a mixture of cuisines,
                                        cultures and choices
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews22.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Architecture + Design </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        A fine dining restaurant celebrating
                                        India's pluralism, the House of Celeste
                                        displays a tropical jungle- themed
                                        design integrated with ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews23.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Luxe Code </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews24.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Archdaily  </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Located in New Delhi's Okhla industrial
                                        area, the development comprises two
                                        multi-tenant buildings placed within
                                        a verdant campus
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn1.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> IFJ </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Preserving Heritage and legal compliances
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Architectural Digest </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        A Gurugram Office with adorned Indian folk art
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn3.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Archello </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Spread across 2000 sq. ft at
                                        Delhi's Defence Colony market,
                                        Ekaya Thaan brings back the
                                        glory of Banarasi textiles,
                                        celebrating each ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn4.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Home & Design Trends </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Trilegal demonstrates a stellar
                                        narrative of Indian crafts including diverse arts
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn5.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> The Hindu </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Ankur Choksi's take on How the
                                        future of luxury real estate
                                        is taking the eco route
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn6.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Design Diffusion </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Ambrish Arora on How to interpret
                                        design authenticity to best express
                                        your specific context covering
                                        RAAS Chhatrasagar
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn7.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ArchDaily </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        REFURBISHMENT IN ARCHITECTURE - The
                                        Baradari at City Palace Jaipur in
                                        Rajasthan cites an insightful
                                        example of how design brings
                                        inherent ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn8.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> FuturArc </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The retro charm of energy-efficient Max House, New Delhi
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn9.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> The Leela Magazine </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Asha Sairam, Principal at Studio
                                        Lotus, feels that while the fare
                                        serves as a starting point,
                                        the fundamentals of anthropometry
                                        and ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn10.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Southeast Asia Building </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The Club at the Trees in
                                        Mumbai has been conceptualised by
                                        Studio Lotus and GPL Vikhroli as
                                        a collection of buildings abstracted
                                        from ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn11.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Industrial style Royal Enfield Garage Cafe in Goa </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Studio Lotus’ approach to the
                                        architecture and interior design
                                        of the goan venue of Indian automaker
                                        company Royal Enfield builds ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn12.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Domus India </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        This house continues to connect the
                                        inside and the outside as much as
                                        it veils one from the other — juggling
                                        context!”
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn13.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> designboom </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Situated in the heart of the
                                        old walled city of Jodhpur,
                                        awaits a charismatic boutique
                                        hotel…”
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn14.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Area, Italy </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Stacked House is designed as an
                                        airy, day-lit sanctuary with all
                                        spaces cross-ventilated throughout
                                        the day.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/FeaturedIn15.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> How green is your building? </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        With Odisha's Krushi Bhawan
                                        being shortlisted for a global
                                        architecture award, we look at
                                        how architects bring sustainability
                                        to what ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FeaturedIn