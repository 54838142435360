import React from 'react'
import { NavLink } from 'react-router-dom'

const Adaptive = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/AdaptiveBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> architecture </h5>
                            <h6> adaptive re-use </h6>
                            <p>
                                We believe in abstracting memories of the site to build paradigms that heighten the relationship between the old and the new. We explore ways of contemporising artisan skill, traditional craft and material to create new meaning and new conversations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AdaptiveSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur  </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        World’s Best Holiday Building at
                                        WAF Barcelona and an Aga Khan
                                        Award for Architecture Nominee,
                                        the luxury boutique hotel of RAAS ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AdaptiveSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Baradari At City Palace Jaipur </h1>
                                                <h5> Hospitality | Adaptive Re-Use </h5>
                                                <p>
                                                    Creative Re-use Winner at
                                                    INSIDE - World Festival of
                                                    Interiors in Berlin, Baradari
                                                    at City Palace Jaipur explores
                                                    how design can bring ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AdaptiveSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Hospitality | Adaptive Re-Use | Mixed Use </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the next
                                                    level of immersive brand experiences
                                                    for Royal Enfield, Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AdaptiveSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Mixed Use </h5>
                                    <p>
                                        Recognised by WAN, Fast Company
                                        and Architizer A+ Awards, Imagine
                                        Studio at The Trees weaves nature,
                                        heritage and urbanism through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AdaptiveSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Devigarh </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        Located in the Aravali Hills,
                                        RAAS Devigarh is an all-suites
                                        luxury hotel housed within an
                                        18th century palace fortress.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AdaptiveSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                                <h5> Adaptive Re-Use </h5>
                                                <p>
                                                    A sensitively-approached award-winning
                                                    design, Café Lota & the Museum Shop
                                                    revitalizes the National Crafts
                                                    Museum hub using local ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AdaptiveSection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Mehrangarh Museum Shop </h1>
                                                <h5> Adaptive Re-Use </h5>
                                                <p>
                                                    Detailed research and an insightful
                                                    approach to adaptive reuse removes
                                                    layers of modern day renovations to
                                                    reveal the exquisite original ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AdaptiveSection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Khoj Studios </h1>
                                    <h5> Adaptive Re-Use | Institutional </h5>
                                    <p>
                                        An experimental art laboratory
                                        in an Urban village ghetto in
                                        New Delhi, Khoj is designed as
                                        an organic space where dialogue,
                                        exchange, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Adaptive