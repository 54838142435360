import React from 'react'
import "./Home.css"
import { NavLink } from 'react-router-dom'


const Home = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 " >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../Img/Silder1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                                <div className='Main-Silder-Content'>
                                    <p>
                                        WE DO AWESOME THINGS
                                    </p>
                                    <h6>
                                        DESIGN CONSULTANCY &  <br />
                                        TURNKEY SOLUTION
                                    </h6>
                                    <button>
                                        <span>
                                            PAN INDIA PROJECTS
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/SilderUpdate2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                                <div className='Main-Silder-Content '>
                                    <p>
                                        BEST INTERIOR DESIGNERS IN HYDERABAD
                                    </p>
                                    <h6>
                                        INTERIOR DESIGN
                                    </h6>
                                    <button>
                                        <span>
                                            PAN INDIA PROJECTS
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Silder3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                                <div className='Main-Silder-Content-Last '>
                                    <p>
                                        BEST INTERIOR DESIGNERS IN PUNE
                                    </p>
                                    <h6>
                                        AHA Concept
                                    </h6>
                                    <button>
                                        <span>
                                            PAN INDIA PROJECTS
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid ">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Motodo Pizzeria </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        This new eatery by Reliance Brands
                                        Limited brings the quaint charm of
                                        Southern Italy to BKC's Jio World
                                        Drive
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className='Comman-Paragraph'>
                                            <p>
                                                <span> We are an interdisciplinary team that aspires to craft spaces </span> that address the emerging future of work, leisure and living, and the manner in which we build.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Earth and Glass House </h1>
                                                <h5> Homes | Homes </h5>
                                                <p>
                                                    Interconnected Volumes establish a
                                                    dialogue between the Indoors and the
                                                    Outdoors at this weekend retreat
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection1-2.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Chhatrasagar </h1>
                                    <h5> Hospitality | Hotels </h5>
                                    <p>
                                        A wildlife camping experience that
                                        reinterprets the historical, and weaves in
                                        the biodiversity of its setting with
                                        resilient ways of building, RAAS Chhatrasagar
                                        seeks to stand out from the crowd by blending
                                        in with its environment.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4  Main-grid-box-margin-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Max House, Okhla </h1>
                                    <h5> Mixed Use </h5>
                                    <p>
                                        A Commercial Complex in the Heart of New Delhi Designed to Push the Envelope for Sustainability.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="row g-4">
                                <div className="col-lg-12 col-md-6" >
                                    <div className="image HoverEffect ">
                                        <img className='' src={require("../Img/HomeSection2-2.png")} alt="" />
                                        <div className='content'>
                                            <h1> Building with the terrain </h1>
                                            <h5> White Paper </h5>
                                            <p>
                                                How we can design better in the Himalayas.
                                            </p>
                                            <NavLink to='' className='btn2_1 btn2'>
                                                <h1> READ MORE</h1>
                                                <span> <i class="fa-solid fa-angle-right"></i> </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6">
                                    <div className='Comman-Paragraph'>
                                        <p>
                                            While staying abreast of relevant global technologies, <span> our work is rooted in a keen awareness of  </span> our environment and the context we are building in.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid ">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Villa in the Woods </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        An Eco-Conscious Sanctuary in the Himalayas
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection3-3.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> FIND Asia Design Fair </h1>
                                                <h5> Events </h5>
                                                <p>
                                                    Ankur Choksi, as part of panel
                                                    discussion in The Fourth Pillar of
                                                    Sustainability at FIND Global Summit
                                                    2022
                                                </p>
                                                <NavLink to='' className='btn2_1 btn2'>
                                                    <h1> READ MORE</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection3-4.png")} alt="" />
                                            <div className='contentThird'>
                                                <h1> Living in Light </h1>
                                                <h5> White Paper </h5>
                                                <p>
                                                    Daylight as an integral architectural element
                                                    By Sidhartha Talwar
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection3-2.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quadrant House </h1>
                                    <h5> Homes | Homes </h5>
                                    <p>
                                        A multi-generational residence on
                                        the outskirts of Raipur, The Quadrant
                                        House blends new notions of luxury
                                        with nature and light.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 d-flex  grid-box">
                        <div className="col-lg-4 col-md-4">
                            <div className='Comman-Paragraph'>
                                <p>
                                    Be it master plans, buildings or interiors, <span> our process focuses on innovation </span> that enriches our Clients’ lives and businesses, and aims to add value to all it touches.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection4_1.png")} alt="" />
                                <div className='content'>
                                    <h1> See Saw Cafe </h1>
                                    <h5> F  and  B </h5>
                                    <p>
                                        An All-Day Pet-Friendly Cafe at Jio
                                        World Drive features a Nature-Inspired
                                        Palette to echo its fresh, homegrown
                                        menu.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid ">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection5_1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quorum </h1>
                                    <h5> Leisure | F & B </h5>
                                    <p>
                                        A seamless Spatial Experience combining
                                        Leisure & Learning, crafted to
                                        create an Urban Commune
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className='Comman-Paragraph'>
                                            <p>
                                                <span> We are an interdisciplinary team that aspires to craft spaces </span> that address the emerging future of work, leisure and living, and the manner in which we build.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection5-2.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> IFJ </h1>
                                                <h5> Featured In | Retail </h5>
                                                <p>
                                                    The Jaypore store creates a modular
                                                    immersive retail experience reflecting
                                                    the brand’s core values and unique
                                                    Indian signature.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection5-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Mixed Use | Brand Experiences </h5>
                                    <p>
                                        Recognised by WAN, Fast Company
                                        and Architizer A+ Awards, Imagine
                                        Studio at The Trees weaves
                                        nature, heritage and urbanism
                                        through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid ">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection6-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Integrated Production Facility for Organic India </h1>
                                    <h5> Workplace </h5>
                                    <p>
                                        A LEED Platinum-Rated development
                                        that encompasses the full production
                                        process of the Wellness Brand
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection6-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Masti Cocktails & Cuisine </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A vibrant and eclectic F&B
                                                    destination, Masti is infused
                                                    with all the personality of
                                                    India, without any of the
                                                    clichés.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/HomeSection6-4.png")} alt="" />
                                            <div className='content'>
                                                <h1> Private Residence in Lutyens' Delhi </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Breathing New Life Into an Existing
                                                    Neoclaasical Structure for
                                                    21st-Century Living
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection6-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Baradari At City Palace Jaipur </h1>
                                    <h5> Hospitality | Adaptive Re-Use | F & B </h5>
                                    <p>
                                        Creative Re-use Winner at INSIDE - World
                                        Festival of Interiors in Berlin,
                                        Baradari at City Palace Jaipur
                                        explores how design can bring ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section >
                <div className="container-fluid ">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/HomeSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Motodo Pizzeria </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        This new eatery by Reliance Brands
                                        Limited brings the quaint charm of
                                        Southern Italy to BKC's Jio World
                                        Drive
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="row g-4">
                                <div className="col-lg-12 col-md-6" >
                                    <div className="image HoverEffect ">
                                        <img className='' src={require("../Img/HomeSection2-2.png")} alt="" />
                                        <div className='content'>
                                            <h1> RAAS Chhatrasagar </h1>
                                            <h5> Hospitality | Hotels </h5>
                                            <p>
                                                A wildlife camping experience that
                                                reinterprets the historical, and weaves in
                                                the biodiversity of its setting with
                                                resilient ways of building, RAAS Chhatrasagar
                                                seeks to stand out from the crowd by blending
                                                in with its environment.
                                            </p>
                                            <NavLink to='' className='btn btn1'>
                                                <h1>VIEW PROJECT</h1>
                                                <span> <i class="fa-solid fa-angle-right"></i> </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6">
                                    <div className='Comman-Paragraph'>
                                        <p>
                                            While staying abreast of relevant global technologies, <span> our work is rooted in a keen awareness of  </span> our environment and the context we are building in.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default Home