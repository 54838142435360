import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsLeisure = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/LeisureBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> leisure </h6>
                            <p>
                                While working on spaces that relax and entertain, our focus is on the ability of the space to take the user into an environment that transports them away from the everyday and the mundane.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/LeisureSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Quorum </h1>
                                    <h5> Leisure | F & B </h5>
                                    <p>
                                        A seamless Spatial Experience combining
                                        Leisure & Learning, crafted to
                                        create an Urban Commune
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/LeisureSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Fields Club </h1>
                                                <h5> Leisure </h5>
                                                <p>
                                                    A clubhouse designed to provide a
                                                    ‘Live, Work and Play’ experience to
                                                    Bengaluru’s young working professionals
                                                    within a suburban ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/LeisureSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Upstage Club, Roseate House </h1>
                                                <h5> Leisure | Hotels | F & B </h5>
                                                <p>
                                                    A Contemporary Club that Embodies
                                                    the Flagship Brand’s Business and
                                                    Hospitality Experience
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/LeisureSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> The Club at The Trees </h1>
                                    <h5> Leisure </h5>
                                    <p>
                                        Anchoring the narrative of an invigorated
                                        realm, The Club at The Trees bridges the
                                        modern and the historic, the built
                                        and the natural, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/LeisureSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Clubhouse at Antara Senior Living </h1>
                                    <h5> Leisure </h5>
                                    <p>
                                        Part of the Max India enterprise,
                                        Antara in Dehradun is a first-of-its-kind
                                        senior living community that bases
                                        the premise of its design ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/LeisureSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> PVR Kochi </h1>
                                                <h5> Leisure </h5>
                                                <p>
                                                    The spaces of PVR’s first and biggest
                                                    multiplex in Kochi are tied together
                                                    in a single fluid architectural gesture
                                                    creating an impactful ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/LeisureSection2-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Vardaan </h1>
                                    <h5> Leisure </h5>
                                    <p>
                                        A streamlined and serene sequence
                                        of spaces, materials and graphics
                                        helps a new wellness brand communicate
                                        its positioning of living ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsLeisure