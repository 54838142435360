import React from 'react'
import { NavLink } from 'react-router-dom'

const NewsAndViews = () => {
    return (
        <>


            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/NewsAndViewsBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> news and views </h6>
                            <p>
                                Studio Lotus has close to 100 awards in India and internationally. The practice has presented at prestigious platforms where we have addressed our role as a thought leader in the design industry. We have been covered extensively in leading print and online features across the world.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews1.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IFJ </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The Jaypore store creates a modular
                                        immersive retail experience reflecting
                                        the brand’s core values and unique
                                        Indian signature.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Elle Decor </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The new Masti restaurant at The
                                        Dubai Edition Hotel reminisces
                                        Indian culture with maximalism
                                        at its core.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews3.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Building with the terrain </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        How we can design better in the Himalayas
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews4.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Volume Zero </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The House of Celeste explores
                                        India’s multiculturalism, celebrating
                                        its sense of togetherness and
                                        belonging.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews5.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Kuala Lumpur Architecture Festival </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Sidhartha Talwar as
                                        keynote speaker at the Kuala
                                        Lumpur Architecture Festival,
                                        Malaysia
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews6.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Reimagining the way we build </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        Modular construction as a sustainable
                                        and efficient alternative to conventional
                                        building methods
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews7.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Transforming Heritage Properties into Hotels </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        Crafting authentic hospitality experiences
                                        by reinterpreting heritage
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews8.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Luxe Code </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Studio Lotus created this multi
                                        generational residence on the
                                        outskirts of Raipur in India,
                                        which blends new notions of
                                        luxury with ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews9.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Forbes India 2023 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Asha Sairam reflects on her
                                        passions, guiding principals
                                        and the work she enjoys
                                        most -- bringing spaces
                                        to life as part of Forbes ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews10.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> LA Journal </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        The proposal of Imagine Studio at
                                        the Trees extends the boundaries
                                        of the gated development to include
                                        the community and the city, ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews11.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Craft, Beauty and the search for meaning </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        On the relevance of handiwork for Indian contexts
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews12.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Of Material and Memory </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        Creating a cohesive, memorable hospitality experience
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews13.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Elle Decor </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Motodo Pizzeria featured in Elle Decor
                                        on creating a festive atmosphere
                                        with a mixture of cuisines,
                                        cultures and choices
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews14.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Not just another Brick in the Wall </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        Transforming the ubiquitous brick
                                        into forms of contemporary expression.
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews15.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> India Design ID </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Studio Lotus featured in
                                        'In Studio with Kohler',
                                        in collaboration with India Design ID
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews16.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Can we do more with less? </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        A Case for Looking Inwards
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews17.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Building Better Facades </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        How facade design impact a
                                        building's performance and
                                        user well-being
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews18.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IIA Kerala Chapter </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Harsh Vardhan as a
                                        key speaker at IIA Kerala
                                        Chapter Convention 2023
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews19.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Hospitality Design Awards  </h1>
                                    <h5> Awards </h5>
                                    <p>
                                        Hospitality Design announced
                                        RAAS Chhatrasagar as the Winner
                                        of the Lodges and Camps category
                                        at the 18th Annual HD Awards ceremony ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews20.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> FIND Asia Design Fair </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ankur Choksi, as part of panel
                                        discussion in The Fourth Pillar
                                        of Sustainability at FIND
                                        Global Summit 2022
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews21.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> How de we celebrate our living Heritage </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        How contemporary design and
                                        local craft can enrich
                                        each other?
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews22.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Architecture + Design </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        A fine dining restaurant celebrating
                                        India's pluralism, the House of Celeste
                                        displays a tropical jungle- themed
                                        design integrated with ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews23.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Luxe Code </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        ...
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews24.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Archdaily  </h1>
                                    <h5> Featured In </h5>
                                    <p>
                                        Located in New Delhi's Okhla industrial
                                        area, the development comprises two
                                        multi-tenant buildings placed within
                                        a verdant campus
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews25.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Tribal Design Forum </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ankur Choksi as a Key Speaker in Tribal Design Forum
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews26.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Can Design Shape Culture ? </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        This Thing Called Cultural Space By Sidhartha Talwar
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews27.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Building Brand Identity through Design </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        How can spatial interventions impact building? - Asha Sairam
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews28.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> AHEAD Awards </h1>
                                    <h5> Awards </h5>
                                    <p>
                                        RAAS Chhatrasagar bags first
                                        prize in AHEAD Awards under
                                        Lodges, Tents + camps and
                                        Sustainability Category
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews29.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Dezeen Awards </h1>
                                    <h5> Awards </h5>
                                    <p>
                                        Studio Lotus longlisted in Dezeen
                                        Awards 2022 for Architecture
                                        Studio of the Year
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/NewsAndViews30.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> Emergence and Improvisation </h1>
                                    <h5> White Paper </h5>
                                    <p>
                                        Quality of emergence as seen
                                        through a theoretical and professional
                                        lens- By Ankur Choksi
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NewsAndViews