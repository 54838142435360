import React from 'react'
import { NavLink } from 'react-router-dom'

const Blogs = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/BlogBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> blogs </h6>
                            <p>
                                Studio Lotus is a multi-disciplinary design practice whose work seamlessly weaves interior and exterior spaces.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog1.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Evolving Paradigms of Luxury </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        A New Consciousness that favours
                                        Experiences over Possessions
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog2.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> The Future of the Building Industry is Circular </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Is 'Building Green' the solution,
                                        or are there Credible Alternatives?
                                        by Ambrish Arora
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog3.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Innovative use of architectural materials </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Contemporizing Craft, by Pankhuri Goel
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog4.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Living in light </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Daylight as an Integral Architectural
                                        Element, by Sidhartha Talwar
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog5.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> What it means to design sustainably </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Greenwashing has become rampant;
                                        we must recognize the need for
                                        going Truly Green, by Sidhartha Talwar
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog6.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> The future of restaurant design </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Strong Concepts and an emphasis
                                        on Better Engagement spell the
                                        Future of Restaurant Design
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog7.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Coworking spaces and digital nomadism </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        What does the Future of Work
                                        look like? by Ankur Choksi
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog8.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> The tale of retail </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Store vs Click: Instant
                                        gratification or virtual convenience?
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog9.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> People | Place | Time  </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Diving into the Past,
                                        Engaging with People in
                                        the Present, to create
                                        Spaces of the Future
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog10.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Emergence and Improvisation </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Quality of Emergence as seen through a
                                        Theoretical and Professional Lens,
                                        by Ankur Choksi
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog11.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Reimaging urban living </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Designing Compact Homes that incorporate
                                        Daylight and Natural Ventilation
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog12.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> The concept of second homes </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Holiday homes-an escape from urban chaos
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog13.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Evolution of the Jaali at RAAS Jodhpur </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Crafting a dialogue between the
                                        old and the new through authenticity
                                        of materials and workmanship
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog14.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Building brand identity through design </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        How can Spatial Interventions
                                        impact Experiences? by Asha Sairam
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog15.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Can design shape culture? </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        This Thing called Cultural
                                        Space, by Sidhartha Talwar
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog16.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> How do we celebrate our living heritage </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        How can Contemporary Design
                                        and Local Craft enrich
                                        each other?
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog17.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Building better facades </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        How Facade Design impacts a
                                        building's Performance and
                                        User Well-being
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog18.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Not just another brick in the wall </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Transforming the ubiquitous
                                        Brick into forms of Contemporary
                                        Expression
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog19.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Of material and memory </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Creating a Cohesive, Memorable
                                        Hospitality Experience
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog20.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Can we do more with less? </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        A case for Looking Inwards
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog21.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Craft, beauty and the search for meaning </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        On the relevance of Handiwork
                                        for Indian Contexts
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog22.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Transforming heritage properties into hotels </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Reinterpreting heritage to
                                        craft authentic hospitality
                                        experiences
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog23.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Reimagining the way we build </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        Modular construction as a sustainable
                                        and efficient alternative to conventional
                                        building methods
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Blog24.png")} alt="" />
                                <div className='contentFour'>
                                    <h1> Building with the terrain </h1>
                                    <h5> Blog </h5>
                                    <p>
                                        How can we design better in the Himalayas?
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Blogs