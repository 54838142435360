import React from 'react'

const TermsOfUse = () => {
    return (
        <>

            <section className='laila-semibold'>
                <div className="container-fluid">
                    <div className="container">
                        <div className='Disclaimer-Page'>
                            <h2>
                                Terms of use
                            </h2>
                            <p>
                                The content provided by studiolotus.in unless otherwise noted, is copyrighted ©StudioLotus. All use of the studiolotus.in website is subject to the terms and conditions set forth below. All materials contained on studiolotus.in, including all text and images, are copyrighted and are protected worldwide by applicable copyright laws and treaty provisions. They may not be published, reproduced, copied, modified, uploaded, transmitted, posted, or distributed in any way, without Studio Lotus’ prior written consent. The materials on studiolotus.in are provided by Studio Lotus for informational purposes only. Illustrations, photographs, computer drawings, etc. may be the property of Studio Lotus, or illustrators and photographers hired by Studio Lotus, its clients and/or suppliers, and are used on studiolotus.in in accordance with individual agreements.
                            </p>
                            <p>
                                Studio Lotus does not grant any express or implied right to you under any license, copyrights, patents, trademarks or trade secret information. Use of any image (photograph, illustration, computer rendering, etc.) from studiolotus.in requires written permission from Studio Lotus and/or any other party, as appropriate. The website studiolotus.in may contain links to third-party websites. We do not author, edit, or monitor these other sites. Studio Lotus has no responsibility for the protection of personal information on other sites, for the accuracy or availability of information provided on other sites, that Studio Lotus does not control or endorse the content, products, advertising, or other materials presented on these other sites, and that you release Studio Lotus from any claims arising out of or in any way connected with any dispute you may have with another site.
                            </p>
                            <p>
                                Under no circumstances shall Studio Lotus, its subsidiaries, or its respective officers, directors, agents, employees and partners be responsible or liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to studiolotus.in, the services available on studiolotus.in or information contained within studiolotus.in, even if it has been advised of the possibility of such damages. If you violate any of these Terms of Use or if you misuse this website, you agree to defend, indemnify and hold harmless Studio Lotus, its officers, directors, partners, employees, agents and licensees from any and all liability including costs, expenses and attorneys’ fees that arise out of or are related to your violation or misuse. These terms of use are governed by the laws of New Delhi.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TermsOfUse