import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./Footer.css"

const Footer = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/1271379270?text="
                + email;

            window.open(url, '_blank').focus();
        }



        else {
            setErrors(errors);
        }

        alert("Thank you! Your submission has been received!")
        setEmail("");
    }

    return (
        <>

            <section>
                <div className="container-fluid pb-3 " >
                    <div className="row  g-2 d-flex justify-content-center laila-semibold Main-Footer">
                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className=''>
                                <NavLink to='/' style={{ cursor: "pointer" }}>
                                    <img className='FooterLogo' src={require("../Img/FooterLOGO.png")} alt="LogoFooter" title='LogoFooter' />
                                </NavLink>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className='mb-2' style={{ marginTop: "19px" }} >
                                <h6 className=' text-decoration-none  pt-3' style={{ fontSize: "0.8rem", fontWeight: "400", color: "#5d5552", letterSpacing: "2px" }} >ARCHITECTURE</h6>
                            </div>
                            <div className=' pt-3'>
                                <nav className='h6FontFamily1 ' style={{ fontSize: "17px", }}>
                                    <div className=''>
                                        <NavLink to='/all' className='text-decoration-none FooterHover' > <p className=''>ALL</p></NavLink>
                                        <NavLink to='/hospitality' className='text-decoration-none FooterHover' > <p className=''>HOSPITALITY</p></NavLink>
                                        <NavLink to='/adaptive' className='text-decoration-none FooterHover' > <p className=''>ADAPTIVE</p></NavLink>
                                        <NavLink to='/institutional' className='text-decoration-none FooterHover' > <p className=''>INSTITUTIONAL</p></NavLink>
                                        <NavLink to='/culture' className='text-decoration-none FooterHover'> <p className=''>CULTURE</p></NavLink>
                                        <NavLink to='/mixeduse' className='text-decoration-none FooterHover' > <p className=''>MIXED-USE</p></NavLink>
                                        <NavLink to='/homes' className='text-decoration-none FooterHover'> <p className=''>HOMES</p></NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className='mb-2' style={{ marginTop: "19px" }}>
                                <h6 className=' text-decoration-none  pt-3' style={{ fontSize: "0.8rem", fontWeight: "400", color: "#5d5552", letterSpacing: "2px" }} >INTERIORS</h6>
                            </div>
                            <div className=' pt-3'>
                                <nav className='h6FontFamily1 ' style={{ fontSize: "17px", }}>
                                    <div className=''>
                                        <NavLink to='/interiorsall' className='text-decoration-none FooterHover' > <p className=''>ALL</p></NavLink>
                                        <NavLink to='/interiorsleisure' className='text-decoration-none FooterHover' > <p className=''>LEISURE</p></NavLink>
                                        <NavLink to='/interiorshomes' className='text-decoration-none FooterHover' > <p className=''>HOMES</p></NavLink>
                                        <NavLink to='/interiorshotels' className='text-decoration-none FooterHover' > <p className=''>HOTELS</p></NavLink>
                                        <NavLink to='/interiorsfb' className='text-decoration-none FooterHover'> <p className=''>F&B</p></NavLink>
                                        <NavLink to='/brandexperiences' className='text-decoration-none FooterHover' > <p className=''>BRAND EXPERIENCES</p></NavLink>
                                        <NavLink to='/retail' className='text-decoration-none FooterHover'> <p className=''>RETAIL</p></NavLink>
                                        <br />
                                        <NavLink to='/interiorsadaptivereuse' className='text-decoration-none FooterHover'> <p className=''>ADAPTIVE REUSE</p></NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className='mb-2' style={{ marginTop: "19px" }} >
                                <h6 className=' text-decoration-none  pt-3' style={{ fontSize: "0.8rem", fontWeight: "400", color: "#5d5552", letterSpacing: "2px" }} >NEWS AND VIEWS</h6>
                            </div>
                            <div className=' pt-3'>
                                <nav className='h6FontFamily1 ' style={{ fontSize: "17px", }}>
                                    <div className=''>
                                        <NavLink to='/newsandviews' className='text-decoration-none FooterHover' > <p className=''>ALL</p></NavLink>
                                        <NavLink to='/featuredin' className='text-decoration-none FooterHover' > <p className=''>FEATURED IN</p></NavLink>
                                        <NavLink to='/awards' className='text-decoration-none FooterHover' > <p className=''>AWARDS</p></NavLink>
                                        <NavLink to='/blogs' className='text-decoration-none FooterHover' > <p className=''>BLOG</p></NavLink>
                                        <NavLink to='/events' className='text-decoration-none FooterHover'> <p className=''>EVENTS</p></NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className='mb-2' style={{ marginTop: "19px" }} >
                                <h6 className=' text-decoration-none pt-3' style={{ fontSize: "0.8rem", fontWeight: "400", color: "#5d5552", letterSpacing: "2px" }} >ABOUT US</h6>
                            </div>
                            <div className=' pt-3'>
                                <nav className='h6FontFamily1 ' style={{ fontSize: "17px", }}>
                                    <div className=''>
                                        <NavLink to='/aboutus' className='text-decoration-none FooterHover' > <p className=''>ALL</p></NavLink>
                                        <NavLink to='/theteam' className='text-decoration-none FooterHover' > <p className=''>TEAM</p></NavLink>
                                        <NavLink to='/clients' className='text-decoration-none FooterHover' > <p className=''>CLIENTS</p></NavLink>
                                        <NavLink to='/ourvalues' className='text-decoration-none FooterHover' > <p className=''>VALUES</p></NavLink>
                                        <NavLink to='/careers' className='text-decoration-none FooterHover'> <p className=''>CAREERS</p></NavLink>
                                        <NavLink to='/contact' className='text-decoration-none FooterHover' > <p className=''>CONTACT</p></NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 text-white ">
                            <div className='mb-2 d-none d-sm-block' style={{ marginTop: "13px" }}>
                                <ul className=" Icon d-flex align-items-lg-center  pt-3" style={{ listStyle: "none" }} >
                                    <li className="  ">
                                        <NavLink className="text-decoration-none" style={{ color: "#5d5552", fontSize: "14px", letterSpacing: "0.25px", lineHeight: "20px", fontWeight: "200" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            f
                                        </NavLink>
                                    </li>

                                    <li className=" ">
                                        <NavLink className="text-decoration-none" style={{ color: "#5d5552", fontSize: "14px", letterSpacing: "0.25px", lineHeight: "20px", fontWeight: "200" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            t
                                        </NavLink>
                                    </li>

                                    <li className="  ">
                                        <NavLink className="text-decoration-none" style={{ color: "#5d5552", fontSize: "14px", letterSpacing: "0.25px", lineHeight: "20px", fontWeight: "200" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            in
                                        </NavLink>
                                    </li>

                                    <li className=" ">
                                        <NavLink className="text-decoration-none" style={{ color: "#5d5552", fontSize: "14px", letterSpacing: "0.25px", lineHeight: "20px", fontWeight: "200" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            @
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className=' pt-2'>
                                <nav className='h6FontFamily1 text-start' style={{ fontSize: "17px", }}>
                                    <div className='' style={{ color: "#e74011", fontSize: "0.75rem", fontWeight: "400", letterSpacing: "0.15rem", lineHeight: "24px" }}>
                                        HEAR ALL ABOUT STUDIO LOTUS
                                    </div>
                                    <form className='mt-3' ref={form} onSubmit={loginHandle}>
                                        <input type="email" className="form-control pb-2" placeholder='YOUR EMAIL' style={{ letterSpacing: ".2rem", borderRadius: "0px 0px 0px 0px", color: "#d1d1d1", borderBottom: "1px solid #d1d1d1", borderTop: "0px solid white", borderLeft: "0px solid white", borderRight: "0px solid white", fontSize: "0.75rem", }} id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email} required

                                        />
                                        <button type="submit" className="mt-3 p-1 JoinButton "  ><b className='' style={{ fontWeight: "400", fontSize: "0.85rem" }}>JOIN</b></button>
                                    </form>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex p-3 mt-4 justify-content-between align-items-center" >
                        <div className='text-center  ' style={{ color: "#5d5552", fontSize: "11px", letterSpacing: "2px", fontWeight: "200", lineHeight: "16px" }} >
                            COPYRIGHT © 2010 - 2024 STUDIO LOTUS. ALL RIGHTS RESERVED. | <NavLink className="text-decoration-none FooterHover-Bottom" to='/disclaimer'> DISCLAIMER </NavLink> | <NavLink className="text-decoration-none FooterHover-Bottom" style={{color: "#5d5552"}} to='/termsofuse'> TERMS OF USE </NavLink>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer