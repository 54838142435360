import React from 'react'
import { NavLink } from 'react-router-dom'

const MixedUse = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/MixedUseBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> architecture </h5>
                            <h6> mixed use </h6>
                            <p>
                                The success of a large-scale architectural project hinges on being able to bring a fresh perspective at the master planning level, integrating efficiency, user experience, placemaking and sustainability.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/MixedUseSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Max House, Okhla </h1>
                                    <h5> Mixed Use </h5>
                                    <p>
                                        A Commercial Complex in the Heart
                                        of New Delhi Designed to Push
                                        the Envelope for Sustainability.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/MixedUseSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Hospitality | Adaptive Re-Use | Mixed Use </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the next
                                                    level of immersive brand experiences
                                                    for Royal Enfield, Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/MixedUseSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Temenos </h1>
                                                <h5> Mixed Use </h5>
                                                <p>
                                                    Inspired from sleek origami folds,
                                                    a double-skin of perforated aluminium
                                                    panels wraps around this new-age
                                                    commercial complex in Delhi’s ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/MixedUseSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Imagine Studio at The Trees </h1>
                                    <h5> Adaptive Re-Use | Mixed Use </h5>
                                    <p>
                                        Recognised by WAN, Fast Company
                                        and Architizer A+ Awards,
                                        Imagine Studio at The Trees
                                        weaves nature, heritage
                                        and urbanism through ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/MixedUseSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RK Studios </h1>
                                    <h5> Mixed Use </h5>
                                    <p>
                                        GPL’s mixed-use development in
                                        Chembur, located on the site of
                                        the erstwhile R K Studios,
                                        aims to add to Mumbai’s
                                        exuberant and ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/MixedUseSection2-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Patiala Crafts Mela </h1>
                                    <h5> Mixed Use </h5>
                                    <p>
                                        Inspired by the colours of phulkari,
                                        an embroidery technique native
                                        to Punjab, Studio Lotus worked
                                        with brightly coloured cotton
                                        fabrics ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MixedUse