import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsWorkplace = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorAdUseBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> workplace </h6>
                            <p>
                                We create engaging, sustainable workplaces that imaginatively bridge context with emerging needs and technologies. We merge our understanding of a brand’s culture and our expertise in optimising functionality to design work environments that build joy, communities and productivity.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/WorkplaceSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Krushi Bhawan </h1>
                                    <h5> Institutional | Workplace </h5>
                                    <p>
                                        Krushi Bhawan illustrates how
                                        a Government facility can extend
                                        itself to celebrate local context,
                                        craftsmanship and sustainability ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/WorkplaceSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Trilegal office </h1>
                                                <h5> Workplace </h5>
                                                <p>
                                                    Reimagining the brand identity for
                                                    a law firm through the expression
                                                    of Indian craft forms
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/WorkplaceSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> MKM Office </h1>
                                                <h5> Workplace </h5>
                                                <p>
                                                    The design of MKM group’s unconventional
                                                    corporate head office in Jasola,
                                                    New Delhi is based on the idea
                                                    of transparency, a shared ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-3">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/WorkplaceSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Sirpur House  </h1>
                                    <h5> Workplace </h5>
                                    <p>
                                        A tactile interior narrative builds the
                                        “paper story” for Sirpur House, the
                                        corporate headquarters for Sirpur
                                        Paper Mills, as a ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/WorkplaceSection2-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Modi Enterprises </h1>
                                    <h5> Workplace </h5>
                                    <p>
                                        Located in New Friends Colony in
                                        New Delhi, the corporate office
                                        for Modi Enterprises has been
                                        designed as a 'home office',
                                        where all ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsWorkplace