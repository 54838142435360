import React from 'react'

const OurValues = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/OurValuesBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> our values </h6>
                            <p>
                                We believe that what guides us makes us who we are — these are values that we as Studio Lotus are committed to live through our practice.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='OurValues-Size'>
                <div className="container-fluid laila-semibold">
                    <div className="row d-flex justify-content-around g-5 p-2">
                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues1.png")} alt="" />
                            <h2> An Attitude of Gratitude </h2>
                            <p>
                                No matter what the scale or type of work – it is a privilege for us to work on a project. Giving anything short of our best selves to our work is untrue to ourselves.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues2.png")} alt="" />
                            <h2> Innovation as a way of life </h2>
                            <p>
                                ‍Innovation to us is a way of being; it is a practice that we bring in to work everyday. We believe that bold visions are a result of a continuous and relentless pursuit of the elusive state of perfection.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues3.png")} alt="" />
                            <h2> Genius as an act of the Collective </h2>
                            <p>
                                We celebrate individual excellence & accomplishment, while consciously fostering a culture of collaboration & mentorship, vesting each one of us in the success of the other.
                                <br />
                                <br />
                                We work best when individuals who are a part of the process contribute their slice of Genius to it. Our biggest triumphs are an act of Co-creation.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues4.png")} alt="" />
                            <h2> Context is Decisive </h2>
                            <p>
                                ‍We are deeply aware that we create standing on the shoulders of others who came before us and those we have around us. An understanding therefore, of indigenous history and local context, in terms of both skill and material, is critical.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues5.png")} alt="" />
                            <h2> Learning for excellence </h2>
                            <p>
                                ‍Learning is an integral part of who we are. Each one of us as Individuals, and the organization as a whole, takes ownership of developing skills or attitudes that foster excellence — always looking to be out of our comfort zone and always open to change.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues6.png")} alt="" />
                            <h2> Empowered People produce Powerful Work </h2>
                            <p>
                                ‍Our strength lies in our people. We are committed to create an environment that enables individuals to learn, grow and perform in a manner which empowers them — professionally and personally — where each individual is pushed to perform at their fullest potential.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues7.png")} alt="" />
                            <h2> Honoring Relationships & our Word </h2>
                            <p>
                                ‍We deeply respect our clients and their businesses, our collaborators, our vendors, our contractors, our team, our society and above all our planet. To this end we strive to bring out the best in each of these relationships. We are aware of the responsibility that we are entrusted with and do everything in our power to deliver exceeding expectations. Above all, we honour our word, and are willing to be held accountable to it at all times.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues8.png")} alt="" />
                            <h2> Trust as a starting point </h2>
                            <p>
                                ‍We fundamentally trust people. We believe that each person, given an opportunity, will want to be the best they can be and will act with integrity. Our actions are consistent with this belief.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <img className='w-100' src={require("../Img/OurValues9.png")} alt="" />
                            <h2> Bring Love to all we do </h2>
                            <p>
                                Above all else, we believe that love supersedes all logic and use it as our guiding compass in the way we are and in all we do.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OurValues