import React from 'react'

const Culture = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/CultureBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> culture </h6>
                            <p>
                                We are passionate about creating invigorating public spaces that engage communities and elevate the human experience. We believe culture is a great medium to connect people to a larger purpose and anchor its experience through authenticity of approach, timeless values and tactile layers of craft and technology that enhance the narrative.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <h2 className='p-4 laila-semibold' style={{ fontSize: "18px", lineHeight: "1.42857143", color: "#333", fontWeight: "400" }} > Nothing to see here! </h2>
                </div>
            </section>

        </>
    )
}

export default Culture