import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsFB = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorFBbg.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> f & b </h6>
                            <p>
                                Understanding how eating in a communal space has become a very important part of the urban social fabric, our design approach stems from a keen awareness that the restaurant itself has become a space for social exchange.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Motodo Pizzeria </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        This new eatery by Reliance Brands
                                        Limited brings the quaint charm
                                        of Southern Italy to BKC's Jio
                                        World Drive
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> The Quorum </h1>
                                                <h5> Leisure | F & B </h5>
                                                <p>
                                                    A seamless Spatial Experience combining
                                                    Leisure & Learning, crafted to
                                                    create an Urban Commune
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Baradari At City Palace Jaipur </h1>
                                                <h5> Adaptive Re-Use | F & B </h5>
                                                <p>
                                                    Creative Re-use Winner at INSIDE -
                                                    World Festival of Interiors in Berlin,
                                                    Baradari at City Palace Jaipur explores
                                                    how design can bring ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> See Saw Cafe </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        An All-Day Pet-Friendly Cafe at
                                        Jio World Drive features a Nature-Inspired
                                        Palette to echo its fresh, homegrown menu.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Masti Cocktails & Cuisine </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        A vibrant and eclectic F&B
                                        destination, Masti is infused
                                        with all the personality of India,
                                        without any of the clichés.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                                <h5> Retail | Adaptive Re-Use | F & B </h5>
                                                <p>
                                                    A sensitively-approached award-winning
                                                    design, Café Lota & the Museum Shop
                                                    revitalizes the National Crafts
                                                    Museum hub using local ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection2-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Upstage Club, Roseate House </h1>
                                                <h5> Leisure | Hotels | F & B </h5>
                                                <p>
                                                    A Contemporary Club that Embodies the
                                                    Flagship Brand’s Business and
                                                    Hospitality Experience
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection2-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Royal enfield garage cafe </h1>
                                    <h5> Retail | Adaptive Re-Use | F & B | Brand Experiences </h5>
                                    <p>
                                        Crafting a Motorcycling Destination
                                        with a Goan Soul to launch the next
                                        level of immersive brand experiences
                                        for Royal Enfield, Studio ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The House of Celeste </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        A gourmet restaurant in Gurugram
                                        designed to echo the country's enduring
                                        legacy of regional cuisines through
                                        a series of visual storytelling ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection3-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Kainoosh </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A Perspective Global Awards Winner,
                                                    Kainoosh creates an India Modern
                                                    story through its challenging mix
                                                    of spaces to build a brand
                                                    experience ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection3-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Keya </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    Selected as the Best Designed
                                                    Hospitality Space in India by
                                                    VM&RD Awards, Keya buds into its
                                                    own identity as a chic bar in
                                                    a challenging ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection3-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Independence Brewing Company </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        A handcrafted microbrewery that
                                        fosters a global crafted brew
                                        experience through authenticity
                                        and timelessness, the design
                                        of the first ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection4-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Lemp Brewpub & Kitchen </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        Making the brewery a backdrop
                                        of the experience, the first-ever
                                        franchise of American brewing
                                        legend Lemp Brewing Co in
                                        Gurgaon pays ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection4-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Diva GK </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    The redesign of one of Delhi’s
                                                    well-loved restaurants centres
                                                    on the importance of chefs and
                                                    developing the kitchen as the
                                                    main highlight ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection4-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Jamie’s Pizzeria Gurgaon  </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    Studio Lotus expands the idiom
                                                    of minimalism to craft the vibrant
                                                    and immersive design of the first
                                                    standalone Jamie’s Pizzeria in ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection4-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Coast Café </h1>
                                    <h5> Adaptive Re-Use | F & B </h5>
                                    <p>
                                        In the repurposing of spaces
                                        for Ogaan, one of India’s pioneering
                                        multi-brand fashion stores,
                                        the building and interior of Coast ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection5-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Chowk </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        Conceptualised as an extension
                                        to the parent brand, The Chowk
                                        creates an intersect where casual
                                        fine dining and the spirit of
                                        the streets ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection5-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Jamie’s Pizzeria Gurgaon  </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    Studio Lotus expands the idiom
                                                    of minimalism to craft the vibrant
                                                    and immersive design of the first
                                                    standalone Jamie’s Pizzeria in ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection5-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Zerruco </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    A curated material palette
                                                    and insightfully-planned zones
                                                    transform a single space from
                                                    a restaurant in the day to
                                                    a lounge in the ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection5-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Zura </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        Located in a shopping center
                                        in Gurgaon, among a cluster
                                        of several restaurants, the
                                        three-storeyed Zura provides
                                        an all-day dining ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection6-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Hard Rock Café </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        Creating a (re)defining
                                        experience for one of the most
                                        globally-recognised hospitality
                                        brands stamped the project with
                                        both its strongest ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorFBsection6-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Magique </h1>
                                                <h5> F & B </h5>
                                                <p>
                                                    IIID Award Winner for the Best
                                                    Designed Hospitality Space,
                                                    Magique transforms a café to
                                                    an Al fresco restaurant that
                                                    fluidly merges ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorFBsection6-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Shiro </h1>
                                    <h5> F & B </h5>
                                    <p>
                                        Awarded by the Times Group for
                                        its grand textural ambience,
                                        Shiro is designed to transform
                                        itself from a chic dining
                                        experience to ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsFB