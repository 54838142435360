import React from 'react'
import { NavLink } from 'react-router-dom'

const Events = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/EventsBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> events </h6>
                            <p>
                                Studio Lotus is a multi-disciplinary design practice whose work seamlessly weaves interior and exterior spaces.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events1.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Kuala Lumpur Architecture Festival Malaysia </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Sidhartha Talwar as
                                        keynote speaker at the Kuala Lumpur
                                        Architecture Festival, Malaysia
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Forbes India 2023 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Asha Sairam reflects on her
                                        guiding principles and design
                                        passion, as part of Forbes
                                        NextGen designers
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events3.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> India Design ID </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Studio Lotus features in
                                        'In Studio with Kohler' at ID 2023
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events4.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IIA Kerala Chapter </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Harsh Vardhan as a
                                        key speaker at IIA Kerala
                                        Chapter Convention 2023
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events5.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> FIND Asia Design Fair </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ankur Choksi as part of a panel
                                        on The Fourth Pillar of Sustainability
                                        at FIND Global Summit, 2022
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events6.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Tribal Design Forum </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ankur Choksi as a key speaker
                                        in Tribal Design Forum
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events7.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> FOAID - Crafting Meaning, Celebrating Context </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ambrish Arora on 'Creating Meaning,
                                        Celebrating Context' through
                                        Villa in the Woods
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events8.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IndiaNOW: Architecture in the Contemporary Landscape </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Sidhartha Talwar and Ankur
                                        Choksi reflecting upon the
                                        Studio's Goals and Approaches
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events9.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ET Edge | Panel Discussion </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Ankur Choksi on
                                        Buildings of Yesterday,
                                        Today and Tomorrow
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events10.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Super Jury | World Architecture Festival | Villa in the Woods </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Studio Lotus presents Villa
                                        in the Woods in the category
                                        House & Villa (Rural/Nature)
                                        at WAF
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events11.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Super Jury | World Architectural Festival 2021 | RAAS Chhatrasagar </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Studio Lotus presents RAAS Chhatrasagar
                                        under the category Hotel & Leisure,
                                        Completed Buildings at WAF 2021
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events12.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ACE Update Architecture & Design Series 2022 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Senior Team Lead Anusha Bajpai on
                                        'Net Zero Buildings Challenges
                                        'and Resolutions'
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events13.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Unbuilt Book Launch 2021 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Studio Lotus Principal Ambrish
                                        Arora and Founder of sP+A Sameep
                                        Padora at the book launch of Unbuilt
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events14.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Those who Design | Designyatra 2021 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Building for India - Ambrish
                                        Arora on designing and building
                                        for public sector in India
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events15.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ICW Symposium </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Asha Sairam, panelist on Relevance
                                        of Traditional Crafts in Contemporary
                                        Interior Design and Architecture
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events16.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Memorial Lecture by Studio Lotus </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Sidhartha Talwar
                                        and Senior Team Lead Subrata Ray
                                        shared key insights with students
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events17.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Rajasthan Architecture Festival </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Sidhartha Talwar speaks on
                                        Coalescing Modern Materials in
                                        a Traditional Terrain
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events18.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Super Jury | World Architecture Festival | Future Project of the Year </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Principal Ankur Choksi, a jury
                                        member for Future Project of the
                                        Year, at World Architecture
                                        Festival 2021
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events19.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> TOI DesigNXT 2021 </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Ankur Choksi expresses his views
                                        on succesful and sustainable future trends
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Events20.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ARCASIA Forum Dhaka </h1>
                                    <h5> Events </h5>
                                    <p>
                                        Sidhartha Talwar discusses sustainable
                                        construction through frugal innovation
                                    </p>
                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Events