import React from 'react'
import { NavLink } from 'react-router-dom'

const All = () => {

    return (
        <>


            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/ArchitectureBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> architecture </h6>
                            <p>
                                Studio Lotus takes a deeply contextual approach to its work. Our design process looks at sustainability through the multiple lenses of cultural, social and environmental impact.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid ">
                    <div className="row g-4 ">
                        <div className="col-lg-4">
                            <div className="row g-4 d-flex ">
                                <div className="col-lg-12 col-md-6">
                                    <div className="image HoverEffect ">
                                        <img className='' src={require("../Img/AllSection1-1.png")} alt="" />
                                        <div className='content'>
                                            <h1> The Quadrant House </h1>
                                            <h5> Homes </h5>
                                            <p>
                                                A multi-generational residence on
                                                the outskirts of Raipur, The
                                                Quadrant House blends new
                                                notions of luxury with nature
                                                and light.
                                            </p>
                                            <NavLink to='' className='btn btn1'>
                                                <h1>VIEW PROJECT</h1>
                                                <span> <i class="fa-solid fa-angle-right"></i> </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6">
                                    <div className="image HoverEffect ">
                                        <img className='' src={require("../Img/AllSection1-2.png")} alt="" />
                                        <div className='contentThird'>
                                            <h1> Evolution of the Jaali at RAAS Jodhpur  </h1>
                                            <h5>  </h5>
                                            <p>
                                                Located at the base of the Mehrangarh
                                                Fort, the luxury boutique hotel of
                                                RAAS Jodhpur crafts a dialogue
                                                between the old and the new ...
                                            </p>
                                            <NavLink to='' className='btn2_1 btn2'>
                                                <h1> READ MORE</h1>
                                                <span> <i class="fa-solid fa-angle-right"></i> </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect">
                                <img className='' src={require("../Img/AllSection1-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Krushi Bhawan </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        Krushi Bhawan illustrates how a
                                        Government facility can extend
                                        itself to celebrate local context,
                                        craftsmanship and sustainability ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="col-lg-12">
                                <div className="row g-4 d-flex ">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection1-4.png")} alt="" />
                                            <div className='content'>
                                                <h1> Max House, Okhla </h1>
                                                <h5> Mixed Use </h5>
                                                <p>
                                                    A Commercial Complex in the Heart
                                                    of New Delhi Designed to Push
                                                    the Envelope for Sustainability.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className='Comman-Paragraph'>
                                            <p>
                                                <span> Sustainability is intrinsic to our process. We are deeply cognisant of the impact our buildings have on the environment. </span> Our team meticulously maps the course from design to construction across all relevant factors to deliver innovation with responsibility.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4">
                        <div className="col-lg-4">
                            <div className="col-lg-12">
                                <div className="row g-4 d-flex ">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection2-1.png")} alt="" />
                                            <div className='contentSecond'>
                                                <h1> Elle Decor </h1>
                                                <h5> Hospitality </h5>
                                                <p>
                                                    The new Masti restaurant at The
                                                    Dubai Edition Hotel reminisces
                                                    Indian culture with maximalism
                                                    at its core.
                                                </p>
                                                <NavLink to='' className='btn2_1 btn2'>
                                                    <h1> READ MORE</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className='Comman-Paragraph'>
                                            <p>
                                                <span> We take ownership in delivering rich narratives where there is a seamless transition between context, form and the inside-outside.  </span> We believe in designing buildings and spaces that are relevant, responsive and add meaning in the larger fabric through the smallest detail.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection2-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Earth and Glass House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Interconnected Volumes establish a
                                        dialogue between the Indoors
                                        and the Outdoors at this
                                        weekend retreat
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 Main-grid-box-padding-top">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection3-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur  </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        World’s Best Holiday Building at
                                        WAF Barcelona and an Aga Khan Award
                                        for Architecture Nominee, the
                                        luxury boutique hotel of RAAS ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection3-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Imagine Studio at The Trees </h1>
                                                <h5> Adaptive Re-Use | Mixed Use </h5>
                                                <p>
                                                    Recognised by WAN, Fast Company and
                                                    Architizer A+ Awards, Imagine
                                                    Studio at The Trees weaves
                                                    nature, heritage and urbanism
                                                    through ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection3-3.png")} alt="" />
                                <div className='content'>
                                    <h1> Villa in the Woods </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        An Eco-Conscious Sanctuary in the Himalayas
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className='Comman-Paragraph'>
                                <p>
                                    <span> We actively engage in integrating localised skills and resources with state-of-the-art materials and technologies. </span>  Our curated network of experts ensures smooth facilitation of end-to-end services for value-engineered and timely execution of projects across scales and typologies.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection4-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Integrated Production Facility for Organic India </h1>
                                    <h5>  </h5>
                                    <p>
                                        A LEED Platinum-Rated development
                                        that encompasses the full production
                                        process of the Wellness Brand
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection5-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Baradari At City Palace Jaipur </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        Creative Re-use Winner at INSIDE - World
                                        Festival of Interiors in Berlin, Baradari
                                        at City Palace Jaipur explores how
                                        design can bring ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection5-2.png")} alt="" />
                                <div className='content'>
                                    <h1> Private Residence in Lutyens' Delhi </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Breathing New Life Into an
                                        Existing Neoclaasical Structure
                                        for 21st-Century Living
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection5-3.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Chhatrasagar </h1>
                                    <h5> Hospitality </h5>
                                    <p>
                                        A wildlife camping experience that
                                        reinterprets the historical, and
                                        weaves in the biodiversity of
                                        its setting with resilient
                                        ways of ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection6-1.png")} alt="" />
                                <div className='content'>
                                    <h1> House with a Brick Veil  </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Among the top six international homes
                                        at the WAN Awards, the House with a
                                        Brick Veil seeks to negotiate the
                                        fine line of privacy and ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection6-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Royal enfield garage cafe </h1>
                                                <h5> Hospitality | Adaptive Re-Use | Mixed Use </h5>
                                                <p>
                                                    Crafting a Motorcycling Destination
                                                    with a Goan Soul to launch the
                                                    next level of immersive brand
                                                    experiences for Royal Enfield,
                                                    Studio ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection6-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> RAAS Kangra </h1>
                                                <h5> Hospitality </h5>
                                                <p>
                                                    An ongoing boutique hotel in the
                                                    Himalayas, the rhythmic form of
                                                    Raas Kangra gives a new expression
                                                    to the vernacular vocabulary of ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection6-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Mehrangarh Fort Visitor Centre </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        Focused on sustainability and flexibility,
                                        Studio Lotus’s design for new cultural
                                        annexes at the historic precinct
                                        seeks to create ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection7-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Stepped House </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        A hill retreat that maximises
                                        the stunning panoramas of Coonoor,
                                        the form of the Stepped House
                                        is arrived at from the
                                        attempt to make ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection7-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Twin Cubes and a Court  </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Stark cubic volumes comprise
                                                    this pair of minimalist residences
                                                    located alongside a busy highway,
                                                    connected by a tranquil inner
                                                    court. ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection7-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Stacked House  </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    A Compact Residence in a
                                                    Dense Urban Locality Looks
                                                    Inwards to Elevate the
                                                    Living Experience.
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection7-4.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Devigarh </h1>
                                    <h5> Hospitality | Adaptive Re-Use </h5>
                                    <p>
                                        Located in the Aravali Hills,
                                        RAAS Devigarh is an all-suites
                                        luxury hotel housed within an
                                        18th century palace
                                        fortress.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection8-1.png")} alt="" />
                                <div className='content'>
                                    <h1> The Club at The Trees </h1>
                                    <h5> Hospitality </h5>
                                    <p>
                                        Anchoring the narrative of an invigorated
                                        realm, The Club at The Trees bridges
                                        the modern and the historic, the
                                        built and the natural, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection8-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Café Lota & the Museum Store, National Crafts Museum </h1>
                                                <h5> Adaptive Re-Use </h5>
                                                <p>
                                                    A sensitively-approached award-winning
                                                    design, Café Lota & the Museum Shop
                                                    revitalizes the National Crafts
                                                    Museum hub using local ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection8-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Temenos  </h1>
                                                <h5> Mixed Use </h5>
                                                <p>
                                                    Inspired from sleek origami folds,
                                                    a double-skin of perforated
                                                    aluminium panels wraps around
                                                    this new-age commercial complex
                                                    in Delhi’s ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection8-4.png")} alt="" />
                                <div className='content'>
                                    <h1> Khoj Studios </h1>
                                    <h5> Adaptive Re-Use | Institutional </h5>
                                    <p>
                                        An experimental art laboratory in an
                                        Urban village ghetto in New Delhi,
                                        Khoj is designed as an organic
                                        space where dialogue,
                                        exchange, ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection9-1.png")} alt="" />
                                <div className='content'>
                                    <h1> House In A Garden </h1>
                                    <h5> Homes </h5>
                                    <p>
                                        Designed for a couple and their two young
                                        children, House In A Garden seeks to
                                        strengthen the connect between
                                        man and nature
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection9-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Patiala Crafts Mela </h1>
                                                <h5> Mixed Use </h5>
                                                <p>
                                                    Inspired by the colours of phulkari,
                                                    an embroidery technique native to Punjab,
                                                    Studio Lotus worked with brightly
                                                    coloured cotton fabrics ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection9-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Kukreja House  </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    A city bungalow that creates independent
                                                    living spaces with a strong sense
                                                    of interconnectedness and brings
                                                    in warmth, intimacy, light ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection10-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Mehrangarh Museum Shop </h1>
                                    <h5> Adaptive Re-Use </h5>
                                    <p>
                                        Detailed research and an insightful
                                        approach to adaptive reuse removes
                                        layers of modern day renovations
                                        to reveal the exquisite original ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection10-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> National Police Memorial  </h1>
                                                <h5> Institutional </h5>
                                                <p>
                                                    The winning entry at the National
                                                    Architectural Design Competition
                                                    for the National Police Memorial
                                                    gained praise for the thoughtful ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/AllSection10-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Nair House  </h1>
                                                <h5> Homes </h5>
                                                <p>
                                                    Responding to the context of Coonoor,
                                                    a dramatic roof form articulates a
                                                    conceptual residence into piece of
                                                    art nestled the beautiful ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection10-4.png")} alt="" />
                                <div className='content'>
                                    <h1> National Museum of Afghanistan  </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        Bridging the old and the new,
                                        the entry for the International
                                        Architectural Ideas Competition
                                        for the National Museum of
                                        Afghanistan ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-2">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AllSection11-1.png")} alt="" />
                                <div className='content'>
                                    <h1> School of Integral Design </h1>
                                    <h5> Institutional </h5>
                                    <p>
                                        The campus architecture is conceived
                                        as a ﬂexible skeleton; a dynamic
                                        adaptive system that allows itself
                                        to be populated and shaped ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default All