import React from 'react'
import { NavLink } from 'react-router-dom'

const InteriorsHotels = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/InteriorHotelsBG.png")} alt="" />
                        <div className='contentBG'>
                            <h5> interiors </h5>
                            <h6> hotels </h6>
                            <p>
                                We create meaningful experiences in hospitality to address the emerging needs of the most discerning user by consistently applying our understanding of the context, local materials and techniques, authentic luxury and the brand narrative.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHotelsSection1-1.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Jodhpur  </h1>
                                    <h5> Adaptive Re-Use | Hotels </h5>
                                    <p>
                                        World’s Best Holiday Building
                                        at WAF Barcelona and an Aga Khan
                                        Award for Architecture Nominee,
                                        the luxury boutique hotel of RAAS ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorHotelsSection1-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> RAAS Kangra </h1>
                                                <h5> Hotels </h5>
                                                <p>
                                                    An ongoing boutique hotel in the Himalayas,
                                                    the rhythmic form of Raas Kangra gives a
                                                    new expression to the vernacular
                                                    vocabulary of ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorHotelsSection1-3.png")} alt="" />
                                            <div className='content'>
                                                <h1> Taj Theog Resort & Spa </h1>
                                                <h5> Hotels </h5>
                                                <p>
                                                    With the narrative of local crafts
                                                    woven in, the interior design scheme
                                                    for Taj Theog attempts to substantiate
                                                    the sense of luxury ...
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHotelsSection1-4.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Chhatrasagar </h1>
                                    <h5> Hotels </h5>
                                    <p>
                                        A wildlife camping experience that
                                        reinterprets the historical, and
                                        weaves in the biodiversity of its
                                        setting with resilient ways of ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row g-4 ">
                        <div className="col-lg-8">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHotelsSection2-1.png")} alt="" />
                                <div className='content'>
                                    <h1> Aloft Hotel </h1>
                                    <h5> Hotels </h5>
                                    <p>
                                        Interpreting an international hospitality
                                        brand to create a unique spatial experience,\
                                        Aloft stands for all that is fresh and spirited ...
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row g-4 d-flex  grid-box">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/InteriorHomesSection2-2.png")} alt="" />
                                            <div className='content'>
                                                <h1> Upstage Club, Roseate House </h1>
                                                <h5> Leisure | Hotels | F & B </h5>
                                                <p>
                                                    A Contemporary Club that Embodies
                                                    the Flagship Brand’s Business
                                                    and Hospitality Experience
                                                </p>
                                                <NavLink to='' className='btn btn1'>
                                                    <h1>VIEW PROJECT</h1>
                                                    <span> <i class="fa-solid fa-angle-right"></i> </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/InteriorHotelsSection2-3.png")} alt="" />
                                <div className='content'>
                                    <h1> RAAS Devigarh </h1>
                                    <h5> Adaptive Re-Use | Hotels </h5>
                                    <p>
                                        Located in the Aravali Hills,
                                        RAAS Devigarh is an all-suites
                                        luxury hotel housed within an
                                        18th century palace fortress.
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InteriorsHotels