import React, { useEffect } from 'react'
import "./TheTeam.css"
// import "../css/style.css"
import * as bootstrap from 'bootstrap';

const TheTeam = () => {





    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/TeamBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> the team </h6>
                            <p>
                                We are an award-winning interdisciplinary design practice that believes in delivering enriching design solutions through a value–driven process empowering all stakeholders and the environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className='gallery'>
                <div className="container-fluid">
                    <div className="row g-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1 row-cols-sm-2 ">
                        <div className="col">
                            <img src={require("../Img/Team1.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team2.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team3.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team4.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team5.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team6.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team7.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team8.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team9.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team10.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team11.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team12.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team13.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team14.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team15.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team16.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team17.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team18.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team19.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team20.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team21.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team22.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team23.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team24.png")} className='gallery-item' alt="" />
                        </div>

                        <div className="col">
                            <img src={require("../Img/Team25.png")} className='gallery-item' alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <img src={require("../Img/Team1.png")} className='modal-img' alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}


            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button> */}


            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className=''>
                                <div className="row d-flex justify-content-around align-items-center">
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <img src={require("../Img/Team1.png")} className=' modal-img' alt="" />
                                    </div>

                                    <div className="col-xl-7 col-lg-6 col-md-6">
                                        <div className='laila-semibold'>
                                            <h3 className='mb-3 text-danger fw-bold '>
                                                Sidhartha Talwar
                                            </h3>
                                            <h6 className='mb-3'>
                                                PRINCIPAL
                                            </h6>
                                            <p className='' style={{ fontWeight: "400" }}>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et itaque nobis temporibus ipsum provident sapiente laborum alias quidem reprehenderit placeat?
                                            </p>
                                           
                                            <p className='' style={{ fontWeight: "400" }}>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et itaque nobis temporibus ipsum provident sapiente laborum alias quidem reprehenderit placeat?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TheTeam