import React from 'react'
import { Routes, Route } from 'react-router'
import Navbar from './Navbar'
import Home from './Home'
import All from './All'
import Hospitality from './Hospitality'
import Adaptive from './Adaptive'
import Institutional from './Institutional'
import Culture from './Culture'
import MixedUse from './MixedUse'
import Homes from './Homes'
import InteriorsAll from './InteriorsAll'
import Retail from './Retail'
import InteriorsAdaptiveReUse from './InteriorsAdaptiveReUse'
import InteriorsInstitutional from './InteriorsInstitutional'
import InteriorsWorkplace from './InteriorsWorkplace'
import InteriorsLeisure from './InteriorsLeisure'
import InteriorsHomes from './InteriorsHomes'
import InteriorsHotels from './InteriorsHotels'
import BrandExperiences from './BrandExperiences'
import Error from './Error'
import InteriorsFB from './InteriorsFB'
import NewsAndViews from './NewsAndViews'
import FeaturedIn from './FeaturedIn'
import Awards from './Awards'
import Blogs from './Blogs'
import Events from './Events'
import AboutUs from './AboutUs'
import OurValues from './OurValues'
import TheTeam from './TheTeam'
import Clients from './Clients'
import Careers from './Careers'
import Contact from './Contact'
import Footer from './Footer'
import Disclaimer from './Disclaimer'
import TermsOfUse from './TermsOfUse'

const Routing = () => {
    return (
        <>
            <React.Fragment>
                <header className='sticky-top'>
                    <Navbar />
                </header>
                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/all' element={<All />} />
                        <Route exact to path='/hospitality' element={<Hospitality />} />
                        <Route exact to path='/adaptive' element={<Adaptive />} />
                        <Route exact to path='/institutional' element={< Institutional />} />
                        <Route exact to path='/culture' element={< Culture />} />
                        <Route exact to path='/mixeduse' element={< MixedUse />} />
                        <Route exact to path='/homes' element={< Homes />} />
                        <Route exact to path='/interiorsall' element={< InteriorsAll />} />
                        <Route exact to path='/retail' element={< Retail />} />
                        <Route exact to path='/interiorsadaptivereuse' element={< InteriorsAdaptiveReUse />} />
                        <Route exact to path='/interiorsinstitutional' element={< InteriorsInstitutional />} />
                        <Route exact to path='/interiorsworkplace' element={< InteriorsWorkplace />} />
                        <Route exact to path='/interiorsleisure' element={< InteriorsLeisure />} />
                        <Route exact to path='/interiorshomes' element={< InteriorsHomes />} />
                        <Route exact to path='/interiorshotels' element={< InteriorsHotels />} />
                        <Route exact to path='/brandexperiences' element={< BrandExperiences />} />
                        <Route exact to path='/interiorsfb' element={< InteriorsFB />} />
                        <Route exact to path='/newsandviews' element={< NewsAndViews />} />
                        <Route exact to path='/featuredin' element={< FeaturedIn />} />
                        <Route exact to path='/awards' element={< Awards />} />
                        <Route exact to path='/blogs' element={< Blogs />} />
                        <Route exact to path='/events' element={< Events />} />
                        <Route exact to path='/aboutus' element={< AboutUs />} />
                        <Route exact to path='/ourvalues' element={< OurValues />} />
                        <Route exact to path='/theteam' element={< TheTeam />} />
                        <Route exact to path='/clients' element={< Clients />} />
                        <Route exact to path='/careers' element={< Careers />} />
                        <Route exact to path='/contact' element={< Contact />} />
                        <Route exact to path='/disclaimer' element={< Disclaimer />} />
                        <Route exact to path='/termsofuse' element={< TermsOfUse />} />

                        <Route exact to path='*' element={< Error />} />
                    </Routes>
                </main>
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>
        </>
    )
}

export default Routing