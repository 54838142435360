import React from 'react'
import { NavLink } from 'react-router-dom'

const Awards = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/AwardsBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> awards </h6>
                            <p>
                                Studio Lotus is a multi-disciplinary design practice whose work seamlessly weaves interior and exterior spaces.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Main-grid-box'>
                <div className="container-fluid">
                    <div className="row g-4 d-flex ">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards1.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> HOSPITALITY DESIGN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        RAAS Chhatrasagar-Winner in the Lodges
                                        and Camps category at the 18th Annual
                                        HD Awards
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> DEZEEN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Studio Lotus longlisted in Dezeen
                                        Awards 2022 for Architecture
                                        Studio of the Year
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards3.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> AHEAD AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        RAAS Chhatrasagar bags 1st prize
                                        in the Lodges, Tents, Camps and
                                        Sustainability Category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards4.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> World Architecture Festival </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        RAAS Chhatrasagar wins WAF
                                        2021 under Hotel & Leisure
                                        - Completed Buildings category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards5.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> Golden Trezzini Awards </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Krushi Bhawan bags Honorable
                                        Mention in International Golden
                                        Trezzini Awards
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards6.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> DEZEEN AWARDS 2020 </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        The Club at the Trees features
                                        in Dezeen Awards 2020 Architecture
                                        Longlist
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards7.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> KYOORIUS DESIGN </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Cafe Lota & the Museum Shop,
                                        Royal Enfield & Imagine Studio
                                        and In-Book wins for IBC & VECV
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards8.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> World Architecture Festival </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        World Holiday Building of
                                        the Year 2011 to RAAS Jodhpur
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards9.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> WORLD ARCHITECTURE NEWS  </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        House with a Brick Veil &
                                        Imagine Studio chosen as
                                        Finalists in 3 categories
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards10.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IBC AWARDS FOR EXCELLENCE </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Max House wins IBC Award 2021
                                        for Excellence in Built Environment
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards11.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> PRIX VERSAILLES WORLD ARCHITECTURE </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Masti Dubai gets the Special
                                        Prize (Interiors) in the
                                        Restaurants category for
                                        Africa and West Asia
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards12.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> The Merit List </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        RAAS Chhatrasagar by Studio Lotus
                                        is a Citation Project for the Merit List
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards13.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> HUDCO DESIGN </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Studio Lotus wins 1st prize
                                        in Green Buildings category
                                        at HUDCO Design Awards
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards14.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ARCASIA AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Paro by Good Earth bags
                                        Store Design of the Year
                                        at Arcasia Awards
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards15.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> DEZEEN AWARDS 2020 </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Krushi Bhawan features in Dezeen
                                        Awards 2020 Architecture longlist
                                        in the Civic Building Category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards16.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> KYOORIUS DESIGN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Studio Lotus wins Baby Blue
                                        Elephant for Trilegal Office
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards17.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> SURFACE DESIGN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Supreme Winner and Winner in
                                        Public Building Exterior and
                                        Interior Categories
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards18.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> KYOORIUS DESIGN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Ather Experience Centre, Chennai
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards19.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> INSIDE - WORLD FESTIVAL OF INTERIORS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Baradari at City Palace,
                                        Jaipur, Wins in the Creative
                                        Reuse Category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards20.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> AGA KHAN AWARD FOR ARCHITECTURE </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Nomination for RAAS Jodhpur
                                        in the 2013 Awards Cycle
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards21.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> WORLD ARCHITECTURE FESTIVAL </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Highly Commended in the Completed
                                        Office Building Category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards22.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> WORLD ARCHITECTURE NEWS AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Hotel of the Year Award to RAAS Jodhpur
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards23.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> IIA AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Max House is amongst the three
                                        shortlisted entries for
                                        IIA Awards 2020
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/Awards24.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> ACES OF SPACE DESIGN AWARDS </h1>
                                    <h5> AWARDS </h5>
                                    <p>
                                        Mehrangarh Fort Visitor Centre bags
                                        2nd position at Ace of Spaces Design
                                        Awards 2020, Unbuilt Category
                                    </p>
                                    <NavLink to='' className='btn btn1'>
                                        <h1>VIEW PROJECT</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Awards