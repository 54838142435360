import React from 'react'
import "./AboutUs.css"
import { NavLink } from 'react-router-dom'

const AboutUs = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 HoverEffectBG">
                    <div className="imageBG" >
                        <img className='' src={require("../Img/AboutsUsBG.png")} alt="" />
                        <div className='contentBG'>
                            <h6> about us </h6>
                            <p>
                                We are an award-winning interdisciplinary design practice that believes in delivering enriching design solutions through a value–driven process empowering all stakeholders and the environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size'>
                <div className="container-fluid laila-semibold">
                    <div className=''>
                        <h2>
                            Creating Meaning, Celebrating Context
                        </h2>
                    </div>
                    <div className="row d-flex justify-content-around g-5">
                        <div className="col-lg-6 col-md-6">
                            <p>
                                Founded in 2002 by Ambrish Arora, Ankur Choksi and Sidhartha Talwar, Studio Lotus is acknowledged as a thought leader in the country for its work in the domain of Architecture and Spatial Design.
                                <br />
                                <br />
                                Our work is grounded on the principles of Conscious design, an approach that celebrates local resources, cultural influences, a keen attention to detail and an inclusive process. We follow an iterative and incremental methodology of innovation and root our learning in history and local context. We aim to craft benchmark solutions that address society's changing ways of living and working.
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <p>
                                Founded in 2002 by Ambrish Arora, Ankur Choksi and Sidhartha Talwar, Studio Lotus is acknowledged as a thought leader in the country for its work in the domain of Architecture and Spatial Design.
                                <br />
                                <br />
                                Our work is grounded on the principles of Conscious design, an approach that celebrates local resources, cultural influences, a keen attention to detail and an inclusive process. We follow an iterative and incremental methodology of innovation and root our learning in history and local context. We aim to craft benchmark solutions that address society's changing ways of living and working.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-SectionSecond laila-semibold'>
                <div className="container-fluid ">
                    <div className="row d-flex  g-5">
                        <div className="col-lg-8">
                            <img className='w-100' src={require("../Img/AboutsUs1.png")} alt="" />
                        </div>

                        <div className="col-lg-4">
                            <p>
                                Working in a highly collaborative environment, our team combines distinct strengths over multiple domains to shape master plans, buildings, interiors and related endeavors, which are at the cutting edge of design thinking.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='AboutUs-Size-ImgSection'>
                <div className="container-fluid">
                    <div className="row g-4 mt-3">
                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AboutsUs2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> CLIENTS </h1>
                                    <h5> About Us </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AboutsUs2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> TEAM </h1>
                                    <h5> About Us </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AboutsUs2.png")} alt="" />
                                <div className='contentSecond'>
                                    <h1> VALUES </h1>
                                    <h5> About Us </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AboutsUs2.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> CONTACT </h1>
                                    <h5> About Us </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="image HoverEffect ">
                                <img className='' src={require("../Img/AboutsUs2.png")} alt="" />
                                <div className='contentThird'>
                                    <h1> JOBS </h1>
                                    <h5> About Us </h5>

                                    <NavLink to='' className='btn2_1 btn2'>
                                        <h1> READ MORE</h1>
                                        <span> <i class="fa-solid fa-angle-right"></i> </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUs